import React from "react";
import {Row, Col} from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import {AddressDto} from "../generated/api/orderApi";

export interface ShippingAddressProps {
  address: AddressDto
}

const ShippingAddress = ({address} : ShippingAddressProps) => {
  const { t } = useTranslation();

  return(
    <Col className="values">
      <Row>
        <Col>{address.firstName + " " + address.lastName}</Col>
        <Col>{address.address + " " + address.poBox +" " + address.zipCode + " " + address.city + " " + address.country}</Col>
        <Col> <>{t('order.shippingAddress.company.value')}: {address.company}</> </Col>
      </Row>
    </Col>
  );

};

export  default ShippingAddress;