import React, {useState, useEffect, useCallback, MouseEvent} from 'react';
import conf from '../config';
import {
  Button,
  Col,
  Container,
  Pagination,
  Row,
  Spinner
} from 'react-bootstrap';
import BackofficeCart from './BackofficeCart';
import {useSelector} from "react-redux";
import util from "../util";
import {Trans, useTranslation} from 'react-i18next';
import {Token} from "../types/token";
import {AxiosResponse} from "axios";
import {CartDto} from "../generated/api/cartOrchestrationApi";
import {CurrencyDto} from "../generated/api/priceApi";

const Carts = () => {
  const {t} = useTranslation();

  const [carts, setCarts] = useState<CartDto[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [checkedButton, setCheckedButton] = useState("cart")
  const [statusFilter, setStatusFilter] = useState("CART")
  const [paginationItems, setPaginationItems] = useState<Element[]>([]);
  const [search, setSearch] = useState('');
  const [currencies, setCurrencies] = useState<CurrencyDto[]>([]);
  const [isMounted, setIsMounted] = useState(true);
  const token: Token = useSelector((state: any) => state.addAuthData);

  const loadCurrency = useCallback(() => {
    util.serviceCallWrapper({
          method: "GET",
          url: conf.urls.priceService + "/currencies",
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          }
        },
        (result: AxiosResponse) => {
          setCurrencies(result.data.content);
        },
        {},
        () => {
        },
        false
    );
  }, [token]);

  const updateCartListElements = useCallback((pageNumber: number) => {
    util.serviceCallWrapper({
          method: "GET",
          url: conf.urls.cartOrchestration + "?page=" + pageNumber
              + "&size=10&statusFilter=" + statusFilter,
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          }
        },
        (result: AxiosResponse) => {
          let carts: CartDto[] = result.data.content.map((cartIn: CartDto) => {
            return {
              id: cartIn.id,
              currencyId: cartIn.currencyId,
              userId: cartIn.userId,
              total: cartIn.total,
              items: cartIn.items,
            }
          });

          setCarts(carts);
          setTotalPages(result.data.totalPages - 1);

        },
        {},
        () => {
        },
        false
    );
  }, [token, isMounted]);

  const openPage = useCallback((pageNumber: number) => {
    updateCartListElements(pageNumber);
    setCurrentPage(pageNumber);
  }, [updateCartListElements]);

  const createPagination = useCallback(() => {
    let pages: any[] = [];
    for (let number = 0; number <= totalPages; number++) {
      pages.push(
          <Pagination.Item key={number} active={number === currentPage}
                           onClick={() => openPage(number)}>
            {number + 1}
          </Pagination.Item>,
      );
      setPaginationItems(pages);
    }
  }, [openPage, currentPage, totalPages]);

  useEffect(() => {
    document.title  = t('cart.cartMain.cartMainTitle');
    updateCartListElements(currentPage);
    createPagination();
    loadCurrency();
    return () => {
      setIsMounted(false)
    };
  }, [updateCartListElements, createPagination, currentPage, token]);

  const openFirstPage = () => {
    updateCartListElements(0);
    setCurrentPage(0);
  };

  const openLastPage = () => {
    updateCartListElements(totalPages);
    setCurrentPage(totalPages);
  };

  const openNextPage = () => {
    if (currentPage < totalPages) {
      updateCartListElements(currentPage + 1);
      setCurrentPage(currentPage + 1);
    }
  };

  const openPreviousPage = () => {
    if (currentPage > 0) {
      updateCartListElements(currentPage - 1);
      setCurrentPage(currentPage - 1);
    }
  };

  const toggleCheckedButton = (value: string) => {
    setCheckedButton(value);
  }

  const deleteCart = (event: MouseEvent, cart: CartDto) => {
    util.serviceCallWrapper({
          method: "DELETE",
          url: conf.urls.cartOrchestration + '/' + cart.id,
          data: "",
          headers: {
            Authorization: `Bearer ${token.accessToken}`
          }
        },
        () => {
          updateCartListElements(currentPage);
        },
        {
          204: {
            'SUCCESS': 'Cart is deleted!'
          },
          403: {
            'ERROR': 'Cart to be deleted is not found or belongs to other user!.'
          },
          404: {
            'ERROR': 'Cart to be deleted is not found!.'
          },
        },
        () => {
        },
        false
    );
  };


  const renderCart = (cart: CartDto) => {
    return (
        <div className=" directOrder" key={cart.id}>
          <BackofficeCart key={cart.id} cart={cart}
                          accessToken={token.accessToken}
                          currencies={currencies}
                          handleDelete={(e: MouseEvent) => deleteCart(e, cart)}
          />
        </div>
    );
  };

  return (
      <Row>
        <Col xl={1} className="hidden-lg"/>
        <Col xl={10} className="mainContent">
          <Container fluid className="carts">
            {(token.authenticated) ? (
                    (token.isAdminCart) ?

                        <div>
                          <Row className="pageName">
                            <Col xs={{span: 12}} className="label">
                              <Trans
                                  i18nKey="cart.cartsMain.cartMainLabel" // optional -> fallbacks to defaults if not provided
                                  defaults="Carts - Overview" // optional defaultValue
                                  components={{tag: <span/>}}
                              />
                            </Col>
                          </Row>
                          <Row className="backofficeTable">
                            <Col xs={{span: 9}} className="tab">
                              <Button
                                  className={checkedButton === "cart"
                                      ? "button active"
                                      : "button"} onClick={() => {
                                setStatusFilter("CART");
                                setCurrentPage(0);
                                toggleCheckedButton("cart")
                              }}> <>{t(
                                  'cart.cartsMain.backofficeTableTab.all')}</> </Button>
                            </Col>
                            <Col xs={{span: 3}} className="features">
                              <Button className="filter">
                                <img src="/common-icons/search.svg"
                                     style={{width: 18, height: 18}}
                                     alt=''/>
                                <span className="value"> <>{t(
                                    'cart.cartsMain.backofficeTableFilter.value')}</> </span>
                              </Button>
                              <Button className="addNew">
                                <img src="/common-icons/plus-circle-black.svg"
                                     style={{width: 18, height: 18}}
                                     alt=''/>
                                <span className="value"> <>{t(
                                    'cart.cartsMain.backofficeTableAdd.value')}</> </span>
                              </Button>
                            </Col>
                            <Col xs={2}/>
                          </Row>
                          <Row className="tableHeader">
                            <Col xs={2} className="label">
                              <>{t('cart.cartsMain.tableHeader.cartId.label')}</>
                            </Col>
                            <Col xs={2} className="label">
                              <>{t('cart.cartsMain.tableHeader.date.label')}</>
                            </Col>
                            <Col xs={3} className="label">
                              <>{t('cart.cartsMain.tableHeader.userName.label')}</>
                            </Col>
                            <Col xs={3} className="label">
                              <>{t('cart.cartsMain.tableHeader.items.label')}</>
                            </Col>

                            <Col xs={1} className="label">
                            </Col>
                            <Col xs={1} className="label">
                            </Col>
                          </Row>
                          {carts.map((cart) => renderCart(cart))}
                          <Row className="paging">
                            <Col xs={5} className="content"/>
                            <Col xs={7} className="content">
                              <Pagination className="buttons">
                                <Pagination.First onClick={openFirstPage}/>
                                <Pagination.Prev onClick={openPreviousPage}/>
                                <Pagination><>{paginationItems}</></Pagination>
                                <Pagination.Next onClick={openNextPage}/>
                                <Pagination.Last onClick={openLastPage}/>
                              </Pagination>
                            </Col>
                            <Col xs={2}/>
                          </Row>
                        </div>

                        :
                        <div className='messageLogout'>
                          <>{t('cart.cartsMain.messageLogout.messageText')}</>

                        </div>)
                :

                <div className='spinner'>
                  <Spinner animation="border" role="status" size="sm"/>{" "}Authenticating
                </div>
            }
            <Col xl={1} className="hidden-lg"/>

          </Container>
        </Col>
      </Row>

  );
};

export default Carts;