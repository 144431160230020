import React from "react";
import {Row, Col} from "react-bootstrap";
import {useTranslation} from 'react-i18next';
import {UserDto} from "../generated/api/userApi";

export interface UserInfoProps {
  user: UserDto
}

const UserInfo = ({user} : UserInfoProps) => {
  const {t} = useTranslation();

  return(
      <Col className="values">
        <Row>
          <Col> <>{t('cart.userInfo.id.value')}: {user.id}</> </Col>
          <Col> <>{t('cart.userInfo.firstName.value')}: {user.firstName}</> </Col>
          <Col> <>{t('cart.userInfo.lastName.value')}: {user.lastName}</> </Col>
          <Col> <>{t('cart.userInfo.email.value')}: {user.email}</> </Col>
          <Col> <>{t('cart.userInfo.phone.value')}: {user.phone}</> </Col>
        </Row>
      </Col>
  );
};

export  default UserInfo;