import React, {ChangeEvent, useState} from 'react';
import conf from '../config';
import {Button, Col, Row} from "react-bootstrap";
import util from "../util";
import {Trans} from 'react-i18next';
import {AxiosResponse} from "axios";
import {CurrencyDto, PriceDto} from "../generated/api/priceApi";

export interface NewPriceProps {
  accessToken: string | null | undefined,
  currencies: CurrencyDto[],
  currentPage: number,
  updatePriceListElements: (pageNumber: number) => void
}

const NewPrice = ({accessToken, currencies, currentPage, updatePriceListElements} : NewPriceProps) => {

  const [newPrice, setNewPrice] = useState<PriceDto>({
    articleId: '',
    validFrom: '',
    validTo: '',
    minQuantity: 0,
    unitPrice: 0,
    currency: {
      id: '',
      isoCode: '',
      name: '',
      symbol: ''
    }
  });
  const [currencyId, setCurrencyId] = useState('');

  const handleAddPrice = async () => {
    let data = newPrice;

    if (!data?.validFrom?.toString().includes("T00:00:00.000") && data.validFrom
        !== '') {
      data.validFrom = newPrice.validFrom + "T00:00:00.000"
    }
    if (!data?.validTo?.toString().includes("T00:00:00.000") && data.validTo
        !== '') {
      data.validTo = newPrice.validTo + "T00:00:00.000"
    }

    if (currencyId !== '') {
      data.currency = {
        id: currencyId,
        isoCode: '',
        name: '',
        symbol: ''
      }
    }

    util.serviceCallWrapper({
          method: 'POST',
          url: conf.urls.priceService + '/prices',
          data: data,
          headers: {Authorization: `Bearer ${accessToken}`}
        },
        (result: AxiosResponse) => {
          if (result.status === 201) {
            setCurrencyId('');
            setNewPrice({
              articleId: '',
              validFrom: '',
              validTo: '',
              minQuantity: 0,
              unitPrice: 0,
              currency: {
                id: '',
                isoCode: '',
                name: '',
                symbol: ''
              }
            });
          }
          updatePriceListElements(currentPage)
        },
        {
          201: {
            'SUCCESS': 'Price for item ' + data.articleId + ' is created.'
          },
          422: {
            'ERROR': 'Mandatory data to create the price of the item'
                + data.articleId + ' is not present!'
          },
        },
        () => {
          setCurrencyId('');
          setNewPrice({
            articleId: '',
            validFrom: '',
            validTo: '',
            minQuantity: 0,
            unitPrice: 0,
            currency: {
              id: '',
              isoCode: '',
              name: '',
              symbol: ''
            }
          });
        },
        true
    );
  }

  const addPriceAndUpdatePriceList = () => {
    handleAddPrice().then(() => updatePriceListElements(currentPage));
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setNewPrice(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleCurrencyChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const target = event.target;
    const value = target.value;
    setCurrencyId(value)
  }

  return (
      <div className="newPrice">

        <Row className="tableHeader">
          <Col xs={2} className="label">
            <Trans
                i18nKey={'price.newPrice.newPrice.articleNumber.label'}/>
          </Col>
          <Col xs={1} className="label">
          </Col>
          <Col xs={2} className="label">
            <Trans
                i18nKey={'price.newPrice.newPrice.validFrom.label'}/>
          </Col>
          <Col xs={2} className="label">
            <Trans
                i18nKey={'price.newPrice.newPrice.validTo.label'}/>
          </Col>
          <Col xs={1} className="label">
            <Trans
                i18nKey={'price.newPrice.newPrice.minQuantity.label'}/>
          </Col>
          <Col xs={1} className="label">
            <Trans
                i18nKey={'price.newPrice.newPrice.unitPrice.label'}/>
          </Col>
          <Col xs={1} className="label">
            <Trans
                i18nKey={'price.newPrice.newPrice.currency.label'}/>
          </Col>
          <Col xs={2} className="label"/>
        </Row>

        <Row className="tableContent">
          <Col xs={2} className="value">
            <input className="articleIdNew" name="articleId"
                   value={newPrice.articleId}
                   onChange={handleInputChange}/>
          </Col>
          <Col xs={1} className="value">
          </Col>
          <Col xs={2} className="value">
            <input type="date" id="validFrom" name="validFrom"
                   value={newPrice.validFrom}
                   onChange={handleInputChange}/>
          </Col>
          <Col xs={2} className="value">
            <input type="date" id="validTo" name="validTo"
                   value={newPrice.validTo}
                   onChange={handleInputChange}/>
          </Col>
          <Col xs={1} className="value">
            <input className="minQuantity" name="minQuantity"
                   value={newPrice.minQuantity}
                   onChange={handleInputChange}/>
          </Col>
          <Col xs={1} className="value">
            <input className="unitPrice" name="unitPrice"
                   value={newPrice.unitPrice}
                   onChange={handleInputChange}/>
          </Col>
          <Col xs={1} className="value">
            <select className="currencyId" name="currencyId"
                    value={currencyId}
                    onChange={handleCurrencyChange}>
              <option/>
              {currencies.map((currency) => {
                return <option key={currency.id}
                               value={currency.id}>{currency.isoCode}</option>
              })}
            </select>
          </Col>
          <Col xs={2} className="value">
            <Button className="function-button addNewPrice" onClick={() => {
              addPriceAndUpdatePriceList()
            }}>
              <img src="/price-icons/plus-circle.svg"
                   style={{width: 13, height: 13}} alt='delete'/>
              <p><Trans
                  i18nKey={'price.newPrice.newPrice.addNew.value'}/>
              </p>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="divider"> {""}</Col>
        </Row>
      </div>
  );
}

export default NewPrice;
