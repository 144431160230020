import React from "react";
import {Row, Col} from "react-bootstrap";
import util from "../util";
import { useTranslation } from 'react-i18next';
import {OrderEntryDto} from "../generated/api/orderApi";
import {CurrencyDto} from "../generated/api/priceApi";

export interface OrderItemProps {
  item: OrderEntryDto,
  currency: CurrencyDto | undefined
}

const OrderItem = ({item, currency} : OrderItemProps) => {
  const { t } = useTranslation();

  return(
    <Col className="values">
      <Row>
        <Col> <>{t('order.orderItem.orderItem.id.value')}: {item.articleId}</> </Col>
        <Col> <>{t('order.orderItem.orderItem.name.value')}: {item.articleName ? item.articleName : "Not specified"}</> </Col>
        <Col> <>{t('order.orderItem.orderItem.quantity.value')}: {item.quantity}</> </Col>
        <Col> <>{t('order.orderItem.orderItem.unitPrice.value')}: {item.unitPrice ? util.displayPrice(item.unitPrice, currency) : "-"}</> </Col>
        <Col> <>{t('order.orderItem.orderItem.totalPrice.value')}: {item.price ? util.displayPrice(item.price, currency) : "-"}</> </Col>
      </Row>
    </Col>
  );

};

export  default OrderItem;