import React, {ChangeEvent, MouseEvent} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {Trans} from 'react-i18next';
import {CurrencyDto, PriceDto} from "../generated/api/priceApi";

export interface ExistingPriceProps {
  price: PriceDto,
  currencies: CurrencyDto[],
  handleDelete: (e: MouseEvent) => void,
  handleUpdate: (e: MouseEvent) => void,
  handleQuantity: (e: ChangeEvent<HTMLInputElement>) => void,
  handleUnitPrice: (e: ChangeEvent<HTMLInputElement>) => void,
  handleFromValidity: (e: ChangeEvent<HTMLInputElement>) => void,
  handleToValidity: (e: ChangeEvent<HTMLInputElement>) => void,
  handleCurrency: (e: ChangeEvent<HTMLSelectElement>) => void,
}

const ExistingPrice = ({price, currencies, handleDelete, handleUpdate, handleQuantity, handleUnitPrice, handleFromValidity, handleToValidity, handleCurrency} : ExistingPriceProps ) => {

  const displayPriceStatus = (price: PriceDto) => {
    const currentDate = new Date();
    const validFromDate = new Date("" + price.validFrom);
    const validToDate = new Date("" + price.validTo);

    if (price.active === false) {
      return (
          <img src="/price-icons/x-circle-fill.svg"
               style={{width: 18, height: 18}} alt=''/>
      )
    } else if (currentDate.getTime() < validFromDate.getTime()) {
      return (
          <img src="/price-icons/clock-fill.svg"
               style={{width: 18, height: 18}} alt=''/>
      )
    }
    else if (currentDate.getTime() > validToDate.getTime()) {
      return (
          <img src="/price-icons/x-circle-fill.svg"
               style={{width: 18, height: 18}} alt=''/>
      )
    }
    else if (price.active === true) {
      return (
          <img src="/price-icons/check-circle-fill.svg" style={{width: 18, height: 18}}
               alt=''/>
      )
    } else {
      return (
          "-"
      )
    }
  }

  return (
      <Row className="tableContent">
        <Col xs={2} className="value">
          {price.articleId}
        </Col>
        <Col xs={1} className="value">
          {displayPriceStatus(price)}
        </Col>
        <Col xs={2} className="value">
          {price.active ?
                <input type='date'
                     value={price.validFrom}
                     onChange={handleFromValidity}/>
                  :
                <input type='date'
                     value={price.validFrom}
                     onChange={handleFromValidity} disabled/>

          }
        </Col>
        <Col xs={2} className="value">
          {price.active ?
                  <input type='date'
                     value={price.validTo}
                     onChange={handleToValidity}/>
                    :
                  <input type='date'
                     value={price.validTo}
                     onChange={handleToValidity} disabled/>
          }
        </Col>
        <Col xs={1} className="value">
          {price.active ?
              <input className="minQuantity" name="minQuantity"
                     value={price.minQuantity}
                     onChange={handleQuantity}/>
              :
              <input className="minQuantity" name="minQuantity"
                     value={price.minQuantity}
                     onChange={handleQuantity} disabled/>
          }
        </Col>
        <Col xs={1} className="value">
          {price.active ?
              <input className="unitPrice" name="unitPrice"
                     value={price.unitPrice}
                     onChange={handleUnitPrice}/>
              :
              <input className="unitPrice" name="unitPrice"
                     value={price.unitPrice}
                     onChange={handleUnitPrice} disabled/>
          }
        </Col>
        <Col xl={1} className="value">
            <select className="currency" name="currency"
                    value={price.currency.id}
                    onChange={handleCurrency}
                    disabled={!price.active}>
                {currencies.map((currency) => {
                    return <option key={currency.id} value={currency.id}>{currency.isoCode}</option>
                })}
            </select>
        </Col>
        <Col xs={1} className="value">
          {price.active ?
              <Button className="function-button" onClick={(e) => {
                handleUpdate(e)
              }}>
                <img src="/price-icons/arrow-repeat.svg"
                     style={{width: 12, height: 12}} alt='update'/>
                <p><Trans
                    i18nKey={'price.existingPrice.existingPrice.update'}/></p>
              </Button> :
              <Button className="function-button" onClick={(e) => {
                handleUpdate(e)
              }} disabled>
                <img src="/price-icons/arrow-repeat.svg"
                     style={{width: 12, height: 12}} alt='update'/>
                <p><Trans
                    i18nKey={'price.existingPrice.existingPrice.update'}/></p>
              </Button>
          }
        </Col>
        <Col xs={2} xl={1} className="value">
          {price.active ?
              <Button className="function-button" onClick={(e) => {
                handleDelete(e)
              }}>
                <img src="/price-icons/x-circle.svg"
                     style={{width: 12, height: 12}} alt='delete'/>
                <p><Trans
                    i18nKey={'price.existingPrice.existingPrice.delete'}/></p>
              </Button> :
              <Button className="function-button" onClick={(e) => {
                handleDelete(e)
              }} disabled>
                <img src="/price-icons/x-circle.svg"
                     style={{width: 12, height: 12}} alt='delete'/>
                <p><Trans
                    i18nKey={'price.existingPrice.existingPrice.delete'}/></p>
              </Button>
          }
        </Col>
      </Row>
  );
};

export default ExistingPrice;