import {toast, ToastOptions} from 'react-toastify';
import {User} from "oidc-react";

declare global {
    interface Window {
        _env_:any;
    }
}

const conf = {
  urls: {
    backoffice_main_full: window._env_.REACT_APP_BACKOFFICE_MAIN_FULL_URL,
    backoffice_main: window._env_.REACT_APP_BACKOFFICE_MAIN_URL,
    backoffice_cart: window._env_.REACT_APP_BACKOFFICE_CART_URL,
    backoffice_currency: window._env_.REACT_APP_BACKOFFICE_CURRENCY_URL,
    backoffice_price: window._env_.REACT_APP_BACKOFFICE_PRICE_URL,
    backoffice_availability: window._env_.REACT_APP_BACKOFFICE_AVAILABILITY_URL,
    backoffice_user: window._env_.REACT_APP_BACKOFFICE_USER_URL,
    backoffice_order: window._env_.REACT_APP_BACKOFFICE_ORDER_URL,
    cartOrchestration: window._env_.REACT_APP_CART_ORCHESTRATION_URL + "/carts",
    checkoutOrchestration: window._env_.REACT_APP_CHECKOUT_ORCHESTRATION_URL + "/orders",
    priceService: window._env_.REACT_APP_PRICE_SERVICE_URL,
    userService: window._env_.REACT_APP_USER_SERVICE_URL + "/users",
    availabilityService: window._env_.REACT_APP_AVAILABILITY_SERVICE_URL + "/availabilities"
  },
  oidcConfig: {
    onSignIn: async (user: User | null) => {
      window.location.href = window._env_.REACT_APP_BACKOFFICE_MAIN_FULL_URL
    },
    onSignOut: () => {
      window.location.reload();
    },
    authority: 'https://velox-nkuf8w.zitadel.cloud',
    clientId: window._env_.ZITADEL_CLIENT_ID,
    responseType: 'code',
    scope: 'openid profile urn:zitadel:iam:org:domain:primary:velox.zitadel.cloud',
    redirectUri: window._env_.REACT_APP_BACKOFFICE_MAIN_FULL_URL,
    post_logout_redirect_uri: window._env_.REACT_APP_BACKOFFICE_MAIN_FULL_URL,
    autoSignIn: true
  },
  permissions: {
    admin: "Admin",
    admin_cart: "Admin_Cart",
    admin_currency: "Admin_Currency",
    admin_price: "Admin_Price",
    admin_availability: "Admin_Availability",
    admin_product: "Admin_Product",
    admin_user: "Admin_User",
    admin_order: "Admin_Order",
  },
  formatting: {
    dateTime: "DD.MM.YYYY, HH:mm:ss",
    time:"HH:mm:ss",
    date:"DD.MM.YYYY"
  },
  messageOptionsSuccess: {
    position: toast.POSITION.BOTTOM_RIGHT,
    hideProgressBar: true,
    autoClose: 10000
  } as ToastOptions,
  messageOptionsOther: {
    position: toast.POSITION.BOTTOM_RIGHT,
    hideProgressBar: true,
    autoClose:false
  } as ToastOptions,

};
export default conf;