import React from "react";
import {Row, Col} from "react-bootstrap";
import util from "../util";
import { useTranslation } from 'react-i18next';
import {ItemDto} from "../generated/api/cartOrchestrationApi";
import {CurrencyDto} from "../generated/api/priceApi";

export interface ItemProps {
  item: ItemDto,
  currency: CurrencyDto | undefined
}

const Item = ({item, currency}: ItemProps) => {
  const { t } = useTranslation();

  return(
      <Col className="values">
        <Row>
          <Col> <>{t('cart.cartItem.id.value')}: {item.articleId}</> </Col>
          <Col> <>{t('cart.cartItem.name.value')}: {item.name ? item.name : "Not specified"}</> </Col>
          <Col> <>{t('cart.cartItem.quantity.value')}: {item.quantity}</> </Col>
          <Col> <>{t('cart.cartItem.unitPrice.value')}: {item.unitPrice ? util.displayPrice(item.unitPrice, currency) : "-"}</> </Col>
          <Col> <>{t('cart.cartItem.totalPrice.value')}: {item.price ? util.displayPrice(item.price, currency) : "-"}</> </Col>
        </Row>
      </Col>
  );

};

export  default Item;