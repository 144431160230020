import React, {useState} from 'react';
import {Nav, FormText, Form, Row, Col, Button} from "react-bootstrap";
import {NavLink} from 'react-router-dom';
import conf from "../config";
import {Trans} from 'react-i18next';

export interface VerticalMenuProps {
  toggleCollapse: () => void
}

const VerticalMenu = ({toggleCollapse} : VerticalMenuProps) => {

  const [viewNav, setViewNav] = useState(true);

  const handleCollapse = () => {
    setViewNav(!viewNav);
    toggleCollapse();
  }

  return (
      <div>
        {
          viewNav ?
              <div>
                <Nav className="flex-column">
                  <Nav.Item>
                    <NavLink exact={true} to={conf.urls.backoffice_main}>
                      <img src="/menu-icons/house.svg"
                           style={{width: 18, height: 18}}
                           alt=''/>
                      <Form.Text className='link'>
                        <Trans
                            i18nKey={'main.verticalMenu.home.link'}/>
                      </Form.Text>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink to="emptyPage" className="disabled">
                      <img src="/menu-icons/chart-bar.svg"
                           style={{width: 18, height: 18}}
                           alt=''/>
                      <Form.Text className='link'>
                        <Trans
                            i18nKey={'main.verticalMenu.reports.link'}/>
                      </Form.Text>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink to="emptyPage" className="disabled">
                      <img src="/menu-icons/sales-email.svg"
                           style={{width: 18, height: 18}}
                           alt=''/>
                      <Form.Text className='link'>
                        <Trans
                            i18nKey={'main.verticalMenu.sales.link'}/>
                      </Form.Text>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink to="emptyPage" className="disabled">
                      <img src="/menu-icons/barcode.svg"
                           style={{width: 18, height: 18}}
                           alt=''/>
                      <Form.Text className='link'>
                        <Trans
                            i18nKey={'main.verticalMenu.cataloque.link'}/>
                      </Form.Text>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink to={conf.urls.backoffice_cart}>
                      <img src="/menu-icons/cart-fill.svg"
                           style={{width: 18, height: 18}}
                           alt=''/>
                      <Form.Text className='link'>
                        <Trans
                            i18nKey={'main.verticalMenu.cart.link'}/>
                      </Form.Text>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink to={conf.urls.backoffice_currency}>
                      <img src="/menu-icons/currency.svg"
                           style={{width: 18, height: 18}}
                           alt=''/>
                      <Form.Text className='link'>
                        <Trans
                            i18nKey={'main.verticalMenu.currency.link'}/>
                      </Form.Text>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink to={conf.urls.backoffice_price}>
                      <img src="/menu-icons/tags-fill.svg"
                           style={{width: 18, height: 18}}
                           alt=''/>
                      <Form.Text className='link'>
                        <Trans
                            i18nKey={'main.verticalMenu.price.link'}/>
                      </Form.Text>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink to={conf.urls.backoffice_availability}>
                      <img src="/menu-icons/stoplights-fill.svg"
                           style={{width: 18, height: 18}}
                           alt=''/>
                      <Form.Text className='link'>
                        <Trans
                            i18nKey={'main.verticalMenu.availability.link'}/>
                      </Form.Text>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink to={conf.urls.backoffice_order}>
                      <img src="/menu-icons/receipt-list.svg"
                           style={{width: 18, height: 18}}
                           alt=''/>
                      <Form.Text className='link'>
                        <Trans
                            i18nKey={'main.verticalMenu.orders.link'}/>
                      </Form.Text>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink to="emptyPage" className="disabled">
                      <img src="/menu-icons/customers.svg"
                           style={{width: 18, height: 18}}
                           alt=''/>
                      <Form.Text className='link'>
                        <Trans
                            i18nKey={'main.verticalMenu.users.link'}/>
                      </Form.Text>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink to="emptyPage" className="disabled">
                      <img src="/menu-icons/support.svg"
                           style={{width: 18, height: 18}}
                           alt=''/>
                      <Form.Text className='link'>
                        <Trans
                            i18nKey={'main.verticalMenu.helpCenter.link'}/>
                      </Form.Text>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink to="emptyPage" className="disabled">
                      <img src="/menu-icons/settings-gear.svg"
                           style={{width: 18, height: 18}}
                           alt=''/>
                      <Form.Text className='link'>
                        <Trans
                            i18nKey={'main.verticalMenu.settings.link'}/>
                      </Form.Text>
                    </NavLink>
                  </Nav.Item>
                </Nav>
                <div className="footer">
                  <FormText>
                    <Trans
                        i18nKey={'main.verticalMenu.footer.copyRight'}/>
                  </FormText>
                  <FormText>
                    <Trans
                        i18nKey={'main.verticalMenu.footer.allRightsReserved'}/>
                  </FormText>
                  <div className="links">
                    <Row>
                      <Col md="auto" className="terms">
                        <NavLink to="TermosOfUse">
                          <Trans
                              i18nKey={'main.verticalMenu.footer.termOfUse'}/>
                        </NavLink>
                      </Col>
                      <Col md="auto" className="verticalLine">
                        <FormText>
                          <Trans
                              i18nKey={'main.verticalMenu.footer.verticalLine'}/>
                        </FormText>
                      </Col>
                      <Col md="auto" className="privacy">
                        <NavLink to="PrivacyPolicy">
                          <Trans
                              i18nKey={'main.verticalMenu.footer.privacyPolicy'}/>
                        </NavLink>
                      </Col>
                    </Row>
                  </div>
                </div>
                <Nav.Item>
                  <Button className="collapseIcon"
                          onClick={handleCollapse}>
                    <img className="collapseImage"
                         src="/menu-icons/box-arrow-in-left.svg"
                         style={{width: 18, height: 18,}}
                         alt=''/>
                    <Form.Text className='collapse link'>
                      <Trans
                          i18nKey={'main.verticalMenu.footer.collapse'}/>
                    </Form.Text>
                  </Button>
                </Nav.Item>

              </div>
              :
              <div className="VerticalMenuNav">
                <Nav className="flex-column">
                  <Nav.Item>
                    <NavLink exact={true} to={conf.urls.backoffice_main}>
                      <img src="/menu-icons/house.svg"
                           style={{width: 18, height: 18}}
                           alt=''/>

                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink to="emptyPage" className="disabled">
                      <img src="/menu-icons/chart-bar.svg"
                           style={{width: 18, height: 18}}
                           alt=''/>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink to="emptyPage" className="disabled">
                      <img src="/menu-icons/sales-email.svg"
                           style={{width: 18, height: 18}}
                           alt=''/>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink to="emptyPage" className="disabled">
                      <img src="/menu-icons/barcode.svg"
                           style={{width: 18, height: 18}}
                           alt=''/>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink to={conf.urls.backoffice_cart}>
                      <img src="/menu-icons/cart-fill.svg"
                           style={{width: 18, height: 18}}
                           alt=''/>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink to={conf.urls.backoffice_currency}>
                      <img src="/menu-icons/currency.svg"
                           style={{width: 18, height: 18}}
                           alt=''/>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink to={conf.urls.backoffice_price}>
                      <img src="/menu-icons/tags-fill.svg"
                           style={{width: 18, height: 18}}
                           alt=''/>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink to={conf.urls.backoffice_availability}>
                      <img src="/menu-icons/stoplights-fill.svg"
                           style={{width: 18, height: 18}}
                           alt=''/>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink to={conf.urls.backoffice_order}>
                      <img src="/menu-icons/receipt-list.svg"
                           style={{width: 18, height: 18}}
                           alt=''/>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink to="emptyPage" className="disabled">
                      <img src="/menu-icons/customers.svg"
                           style={{width: 18, height: 18}}
                           alt=''/>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink to="emptyPage" className="disabled">
                      <img src="/menu-icons/support.svg"
                           style={{width: 18, height: 18}}
                           alt=''/>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink to="emptyPage" className="disabled">
                      <img src="/menu-icons/settings-gear.svg"
                           style={{width: 18, height: 18}}
                           alt=''/>
                    </NavLink>
                  </Nav.Item>
                </Nav>
                <Nav.Item>
                  <Button onClick={handleCollapse}
                          className="collapseIcon">
                    <img className="collapseImage"
                         src="/menu-icons/box-arrow-in-right.svg"
                         style={{width: 18, height: 18}}
                         alt=''/>
                  </Button>
                  <Form.Text className='collapse link'>
                  </Form.Text>

                </Nav.Item>
              </div>
        }
      </div>
  )
}

export default VerticalMenu;