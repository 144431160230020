import React, {ChangeEvent, MouseEvent, useCallback, useEffect, useState} from 'react';
import conf from '../config';
import {
  Button,
  Col,
  Container,
  Pagination,
  Row,
  Spinner,
} from 'react-bootstrap';
import NewCurrency from "./NewCurrency";
import ExistingCurrency from "./ExistingCurrency";
import {useSelector} from "react-redux";
import util from "../util";
import {useTranslation} from 'react-i18next';
import {Trans} from 'react-i18next';
import {CurrencyDto} from "../generated/api/priceApi";
import {Token} from "../types/token";
import {AxiosResponse} from "axios";

const Currencies : React.FC = () => {
  const {t} = useTranslation();

  const [currencies, setCurrencies] = useState<CurrencyDto[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [addNewFlag, setAddNewFlag] = useState(false);
  const [paginationItems, setPaginationItems] = useState<Element[]>([]);
  const token: Token = useSelector((state: any) => state.addAuthData);


  const updateCurrencyListElements = useCallback((pageNumber: number) => {
    util.serviceCallWrapper({
          method: "GET",
          url: conf.urls.priceService + "/currencies?page=" + pageNumber
              + "&size=10",
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          }
        },
        (result: AxiosResponse) => {
          let currencies: CurrencyDto[] = result.data.content.map((currencyIn: CurrencyDto) => {
            return {
              id: currencyIn.id,
              isoCode: currencyIn.isoCode,
              name: currencyIn.name,
              symbol: currencyIn.symbol
            }
          });
          setCurrencies(currencies);
          setTotalPages(result.data.totalPages - 1);
        },
        {},
        () => {
        },
        false
    );
  }, [token])

  const openPage = useCallback((pageNumber: number) => {
    updateCurrencyListElements(pageNumber);
    setCurrentPage(pageNumber);
  }, [updateCurrencyListElements, setCurrentPage]);

  const createPagination = useCallback(() => {
    let pages: any[] = [];
    for (let number = 0; number <= totalPages; number++) {
      pages.push(
          <Pagination.Item key={number} active={number === currentPage}
                           onClick={() => openPage(number)}>
            {number + 1}
          </Pagination.Item>,
      );
      setPaginationItems(pages);
    }
  }, [openPage, currentPage, totalPages]);

  useEffect(() => {
     document.title  = t('currency.currencyMain.currencyMainTitle') ;
    updateCurrencyListElements(currentPage);
    createPagination();
  }, [updateCurrencyListElements, createPagination, currentPage, token]);

  const openFirstPage = () => {
    openPage(0);
  };

  const openLastPage = () => {
    openPage(totalPages);
  };

  const openNextPage = () => {
    if (currentPage < totalPages) {
      openPage(currentPage + 1);
    }
  };

  const openPreviousPage = () => {
    if (currentPage > 0) {
      openPage(currentPage - 1);
    }
  };

  const handleDeleteClick = (event: MouseEvent, currency: CurrencyDto) => {
    util.serviceCallWrapper({
          method: 'DELETE',
          url: conf.urls.priceService + '/currencies/' + currency.id,
          headers: {Authorization: `Bearer ${token.accessToken}`},
        },
        () => {
          updateCurrencyListElements(currentPage)
        },
        {
          204: {
            'SUCCESS': 'Currency ' + currency.isoCode + ' is deleted.'
          },
          404: {
            'ERROR': 'Currency ' + currency.isoCode
                + ' to be deleted is not found!'
          }
        },
        () => {
        },
        true
    );
  };

  const handleUpdateClick = (event: MouseEvent, currency: CurrencyDto) => {
    let updatedCurrencies = currencies.slice();

    updatedCurrencies.forEach((i) => {
      if (i.id === currency.id) {
        util.serviceCallWrapper({
              method: 'PATCH',
              url: conf.urls.priceService + '/currencies/' + i.id,
              data: i,
              headers: {Authorization: `Bearer ${token.accessToken}`}
            },
            () => {
              updateCurrencyListElements(currentPage)
            },
            {
              200: {
                'SUCCESS': 'Currency ' + i.isoCode + ' is updated.'
              },
              404: {
                'ERROR': 'Currency ' + i.isoCode
                    + ' to be updated is not found!'
              },
              409: {
                'ERROR': 'ID of currency ' + i.isoCode + ' cannot be changed!'
              },
            },
            () => {
            },
            true
        );
      }
    });
  };

  const handleIsoCodeChange = (event: ChangeEvent<HTMLInputElement>, currency: CurrencyDto) => {
    let updatedCurrencies = currencies.slice();
    updatedCurrencies.map((i) => {
      if (i.id === currency.id) {
        return Object.assign(i, {isoCode: event.target.value});
      }
      return i;
    });
    setCurrencies(updatedCurrencies);
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>, currency: CurrencyDto) => {
    let updatedCurrencies = currencies.slice();
    updatedCurrencies.map((i) => {
      if (i.id === currency.id) {
        return Object.assign(i, {name: event.target.value});
      }
      return i;
    });
    setCurrencies(updatedCurrencies);
  };

  const handleSymbolChange = (event: ChangeEvent<HTMLInputElement>, currency: CurrencyDto) => {
    let updatedCurrencies = currencies.slice();
    updatedCurrencies.map((i) => {
      if (i.id === currency.id) {
        return Object.assign(i, {symbol: event.target.value});
      }
      return i;
    });
    setCurrencies(updatedCurrencies);
  };

  const toggleAddNewFlag = () => {
    setAddNewFlag(!addNewFlag);
  }

  const renderCurrency = (currency: CurrencyDto) => {
    return (
        <ExistingCurrency key={currency.id} currency={currency}
                          handleDelete={(e: MouseEvent) => handleDeleteClick(e, currency)}
                          handleUpdate={(e: MouseEvent) => handleUpdateClick(e, currency)}
                          handleIsoCode={(e: ChangeEvent<HTMLInputElement>) => handleIsoCodeChange(e,
                              currency)}
                          handleName={(e: ChangeEvent<HTMLInputElement>) => handleNameChange(e, currency)}
                          handleSymbol={(e: ChangeEvent<HTMLInputElement>) => handleSymbolChange(e, currency)}
        />
    );
  }

  return (
      <Row>
        <Col xl={1} className="hidden-lg"/>
        <Col xl={10} className="mainContent">
          <Container fluid className="currency">
            {(token.authenticated) ? (
                    (token.isAdminCurrency) ?

                        <div>
                          <Row className="pageName">
                            <Col xs={{span: 12}} className="label">
                              <Trans
                                  i18nKey="currency.currencyMain.currencyMainLabel" // optional -> fallbacks to defaults if not provided
                                  defaults="Currencies -  Overview" // optional defaultValue
                                  components={{tag: <span/>}}
                              />
                            </Col>
                          </Row>

                          <Row className="backofficeTable">
                            <Col xs={{offset: 9, span: 3}} className="features">
                              <Button className="filter">
                                <img src="/common-icons/search.svg"
                                     style={{width: 18, height: 18}}
                                     alt=''/>
                                <span className="value"> <>{t(
                                    'currency.currencyMain.backofficeTableFilter.value')}</>:</span>
                              </Button>
                              <Button className="addNew"
                                      onClick={() => (toggleAddNewFlag())}>
                                <img src="/common-icons/plus-circle-black.svg"
                                     style={{width: 18, height: 18}}
                                     alt=''/>
                                <span className="value"><>{t(
                                    'currency.currencyMain.backofficeTableAddNew.value')}</>:</span>
                              </Button>
                            </Col>
                          </Row>

                          {addNewFlag &&
                          <NewCurrency
                              updateCurrencyListElements={updateCurrencyListElements}
                              accessToken={token.accessToken}
                              currentPage={currentPage}/>
                          }

                          <Row className="tableHeader">
                            <Col xs={3} className="label">
                              <>{t('currency.currencyMain.tableHeaderIsoCode.label')}</>
                            </Col>
                            <Col xs={3} className="label">
                              <>{t('currency.currencyMain.tableHeaderName.label')}</>
                            </Col>
                            <Col xs={3} className="label">
                              <>{t('currency.currencyMain.tableHeaderSymbol.label')}</>
                            </Col>
                            <Col xs={1} className="label"/>
                            <Col xs={1} className="label"/>
                            <Col xs={1} className="label"/>
                          </Row>
                          {currencies.map((currency) => renderCurrency(currency))}
                          <Row className="paging">
                            <Col xs={5} className="content"/>
                            <Col xs={7} className="content">
                              <Pagination className="buttons">
                                <Pagination.First onClick={openFirstPage}/>
                                <Pagination.Prev onClick={openPreviousPage}/>
                                <Pagination><>{paginationItems}</></Pagination>
                                <Pagination.Next onClick={openNextPage}/>
                                <Pagination.Last onClick={openLastPage}/>
                              </Pagination>
                            </Col>
                          </Row>
                        </div>
                        :
                        <div className='messageLogout'>
                          <>{t('currency.currencyMain.messageLogout.messageText')}</>
                        </div>)
                :
                <div className='spinner'>
                  <Spinner animation="border" role="status" size="sm"/>{" "}Authenticating
                </div>
            }
          </Container>
        </Col>
        <Col xl={1} className="hidden-lg"/>
      </Row>
  );
};

export default Currencies;
