import React, {useEffect, useState, useCallback} from "react";
import {useSelector} from "react-redux";
import conf from "../config";
import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
  Spinner
} from 'react-bootstrap';
import ExistingOrder from "./ExistingOrder";
import util from "../util";
import {useTranslation} from 'react-i18next';
import {Trans} from 'react-i18next';
import {Token} from "../types/token";
import {AxiosResponse} from "axios";
import {OrderDto} from "../generated/api/orderApi";

const Orders = () => {

  const {t} = useTranslation();
  const [orders, setOrders] = useState<OrderDto[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [checkedButton, setCheckedButton] = useState("ordered")
  const [statusFilter, setStatusFilter] = useState("ORDERED")
  const [paginationItems, setPaginationItems] = useState<Element[]>([]);
  const token: Token = useSelector((state: any) => state.addAuthData);


  const updateOrderListElements = useCallback((pageNumber: number) => {
    util.serviceCallWrapper({
          method: "GET",
          url: conf.urls.checkoutOrchestration + "?page=" + pageNumber
              + "&size=10&statusFilter=" + statusFilter,
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          }
        },
        (result: AxiosResponse) => {
          if (result.data !== '' && result.data !== undefined
              && result.data.content !== undefined) {
            let orders: OrderDto[] = result.data.content.map((orderIn: OrderDto) => {
              return {
                // key: Math.random() * 100,
                id: orderIn.id,
                userId: orderIn.userId,
                orderNum: orderIn.orderNum,
                createTime: orderIn.createTime,
                entries: orderIn.entries,
                shippingAddress: orderIn.shippingAddress,
                billingAddress: orderIn.billingAddress,
                charges: orderIn.charges,
                totalPrice: orderIn.totalPrice,
                currencyId: orderIn.currencyId,
                cartId: orderIn.cartId,
                orderStatus: orderIn.orderStatus,
                paymentStatus: orderIn.paymentStatus,
                shipmentStatus: orderIn.shipmentStatus,
                user: orderIn.user,
              }
            });
            setOrders(orders);
            setTotalPages(result.data.totalPages - 1);
          }
        },
        {},
        () => {
        },
        false
    );
  }, [token, statusFilter]);

  const openPage = useCallback((pageNumber: number) => {
    updateOrderListElements(pageNumber);
    setCurrentPage(pageNumber);
  }, [updateOrderListElements]);

  const createPagination = useCallback(() => {
    let pages: any[] = [];
    for (let number = 0; number <= totalPages; number++) {
      pages.push(
          <Pagination.Item key={number} active={number === currentPage}
                           onClick={() => openPage(number)}>
            {number + 1}
          </Pagination.Item>,
      );
      setPaginationItems(pages);
    }
  }, [openPage, currentPage, totalPages]);

  useEffect(() => {
    document.title  = t('order.orderMain.orderMainTitle');
     updateOrderListElements(currentPage);
    createPagination();
  }, [updateOrderListElements, createPagination, currentPage, token]);

  const openFirstPage = () => {
    updateOrderListElements(0);
    setCurrentPage(0);
  };

  const openLastPage = () => {
    updateOrderListElements(totalPages);
    setCurrentPage(totalPages);
  };

  const openNextPage = () => {
    if (currentPage < totalPages) {
      updateOrderListElements(currentPage + 1);
      setCurrentPage(currentPage + 1);
    }
  };

  const openPreviousPage = () => {
    if (currentPage > 0) {
      updateOrderListElements(currentPage - 1);
      setCurrentPage(currentPage - 1);
    }
  };

  const renderOrder = (order: OrderDto) => {
    return (<ExistingOrder key={order.id} order={order} accessToken={token.accessToken}/>);
  };

  const toggleCheckedButton = (value: string) => {
    setCheckedButton(value);
  }

  return (
      <Row>
        <Col xl={1} className="hidden-lg"/>
        <Col xl={10} className="mainContent">
          <Container fluid className="orders">
            {(token.authenticated) ? (
                    (token.isAdminOrder) ?

                        <div>
                          <Row className="pageName">
                            <Col xs={{span: 12}} className="label">
                              <Trans
                                  i18nKey="order.orders.orderMain.label" // optional -> fallbacks to defaults if not provided
                                  defaults="Orders -  Overview" // optional defaultValue
                                  components={{tag: <span/>}}
                              />
                            </Col>

                          </Row>
                          <Row className="backofficeTable">
                            <Col xs={{span: 9}} className="tab">
                              <Button className={checkedButton === "ordered"
                                  ? "button active" : "button"} onClick={() => {
                                setStatusFilter("ORDERED");
                                setCurrentPage(0);
                                toggleCheckedButton("ordered")
                              }}> <>{t(
                                  'order.orderMain.backofficeTableTab.ordered')}</> </Button>
                              <Button className={checkedButton === "draft"
                                  ? "button active" : "button"} onClick={() => {
                                setStatusFilter("DRAFT");
                                setCurrentPage(0);
                                toggleCheckedButton("draft")
                              }}> <>{t(
                                  'order.orderMain.backofficeTableTab.draft')}</> </Button>
                              <Button className={checkedButton === "all"
                                  ? "button active" : "button"} onClick={() => {
                                setStatusFilter("");
                                setCurrentPage(0);
                                toggleCheckedButton("all")
                              }}> <>{t(
                                  'order.orderMain.backofficeTableTab.all')}</> </Button>
                            </Col>
                            <Col xs={{span: 3}} className="features">
                              <Button className="filter">
                                <img src="/common-icons/search.svg"
                                     style={{width: 18, height: 18}}
                                     alt=''/>
                                <span className="value"> <>{t(
                                    'order.orderMain.backofficeTableFilter.value')}</> </span>
                              </Button>
                              <Button className="addNew">
                                <img src="/common-icons/plus-circle-black.svg"
                                     style={{width: 18, height: 18}}
                                     alt=''/>
                                <span className="value"> <>{t(
                                    'order.orderMain.backofficeTableAdd.value')}</> </span>
                              </Button>
                            </Col>
                            <Col xs={2}/>
                          </Row>
                          <Row className="tableHeader">
                            <Col xs={2} className="label">
                              <>{t('order.ordersMain.tableHeader.orderId.label')}</>
                            </Col>
                            <Col xs={2} className="label">
                              <>{t('order.ordersMain.tableHeader.date.label')}</>
                            </Col>
                            <Col xs={1} className="label">
                              <>{t('order.ordersMain.tableHeader.status.label')}</>
                            </Col>
                            <Col xs={2} className="label">
                              <>{t('order.ordersMain.tableHeader.userName.label')}</>
                            </Col>
                            <Col xs={1} className="label">
                              <>{t('order.ordersMain.tableHeader.items.label')}</>
                            </Col>
                            <Col xs={1} className="label">
                              <>{t('order.ordersMain.tableHeader.saleChannel.label')}</>
                            </Col>
                            <Col xs={1} className="label">
                              <>{t('order.ordersMain.tableHeader.shippingMethod.label')}</>
                            </Col>
                            <Col xs={2} className="label"/>
                          </Row>
                          {orders.map((order) => renderOrder(order))}
                          <Row className="paging">
                            <Col xs={5} className="content"/>
                            <Col xs={7} className="content">
                              <Pagination className="buttons">
                                <Pagination.First onClick={openFirstPage}/>
                                <Pagination.Prev onClick={openPreviousPage}/>
                                <Pagination><>{paginationItems}</></Pagination>
                                <Pagination.Next onClick={openNextPage}/>
                                <Pagination.Last onClick={openLastPage}/>
                              </Pagination>
                            </Col>
                            <Col xs={2}/>
                          </Row>
                        </div>
                        :
                        <div className='messageLogout'>

                          <>{t('order.ordersMain.messageLogout.messageText')}</>

                        </div>)
                :
                <div className='spinner'>
                  <Spinner animation="border" role="status" size="sm"/>{" "}Authenticating
                </div>
            }

          </Container>
        </Col>
        <Col xl={1} className="hidden-lg"/>
      </Row>

  );

};

export default Orders;