import React, {useEffect, useState, useCallback} from 'react';
import {
  Popover,
  OverlayTrigger,
  Button,
  Spinner,
  Row,
  Col
} from 'react-bootstrap';
import Header from './Header'
import VerticalMenu from "./VerticalMenu";
import Body from "./Body";
import conf from '../config';
import {useDispatch} from "react-redux";
import {setCurrentAuthData} from "../actions/authData";
import Carts from "../cart/Carts";
import Currencies from "../currency/Currencies";
import Prices from "../price/Prices";
import Availability from "../availability/Availabilities";
import {HashRouter as Router, Route} from "react-router-dom";
import Orders from "../order/Orders";
import {useAuth} from 'oidc-react';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

const MainPage = () => {

  const { t } = useTranslation();
  const [idToken, setIdToken] = useState<string | null | undefined>(null);
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [isAdminCart, setIsAdminCart] = useState<boolean>(false);
  const [isAdminCurrency, setIsAdminCurrency] = useState<boolean>(false);
  const [isAdminPrice, setIsAdminPrice] = useState<boolean>(false);
  const [isAdminAvailability, setIsAdminAvailability] = useState<boolean>(false);
  const [isAdminProduct, setIsAdminProduct] = useState<boolean>(false);
  const [isAdminUser, setIsAdminUser] = useState<boolean>(false);
  const [isAdminOrder, setIsAdminOrder] = useState<boolean>(false);
  const [collapse, setCollapse] = useState<boolean>(false);
  const auth = useAuth();
  const dispatch = useDispatch();


  const checkUser = useCallback(async () => {
    if (await auth && await auth?.userData) {
      const userInfo = auth?.userData?.profile;
      const idToken = auth?.userData?.id_token;
      const accessToken = auth?.userData?.access_token;
      let isAdminCart = false;
      let isAdminCurrency = false;
      let isAdminPrice = false;
      let isAdminAvailability = false;
      let isAdminProduct = false;
      let isAdminUser = false;
      let isAdminOrder = false;
      setIdToken(idToken);
      setAuthenticated(true);

      const userPermissions = (userInfo && userInfo['urn:zitadel:iam:org:project:roles'])
      !== undefined ?(userInfo && userInfo['urn:zitadel:iam:org:project:roles'][0]) : undefined;

      if (userPermissions && userPermissions.hasOwnProperty(
          conf.permissions.admin)) {
        isAdminCart = true;
        isAdminCurrency = true;
        isAdminPrice = true;
        isAdminAvailability = true;
        isAdminProduct = true;
        isAdminUser = true;
        isAdminOrder = true;
      } else {
        if (userPermissions && userPermissions.hasOwnProperty(
            conf.permissions.admin_cart)) {
          isAdminCart = true;
        }
        if (userPermissions && userPermissions.hasOwnProperty(
            conf.permissions.admin_currency)) {
          isAdminCurrency = true;
        }
        if (userPermissions && userPermissions.hasOwnProperty(
            conf.permissions.admin_price)) {
          isAdminPrice = true;
        }
        if (userPermissions && userPermissions.hasOwnProperty(
            conf.permissions.admin_availability)) {
          isAdminAvailability = true;
        }
        if (userPermissions && userPermissions.hasOwnProperty(
            conf.permissions.admin_product)) {
          isAdminProduct = true;
        }
        if (userPermissions && userPermissions.hasOwnProperty(
            conf.permissions.admin_user)) {
          isAdminUser = true;
        }
        if (userPermissions && userPermissions.hasOwnProperty(
            conf.permissions.admin_order)) {
          isAdminOrder = true;
        }
      }
      setIsAdminCart(isAdminCart);
      setIsAdminCurrency(isAdminCurrency);
      setIsAdminPrice(isAdminPrice);
      setIsAdminAvailability(isAdminAvailability);
      setIsAdminProduct(isAdminProduct);
      setIsAdminUser(isAdminUser);
      setIsAdminOrder(isAdminOrder);
      dispatch(setCurrentAuthData({userInfo, idToken, accessToken, authenticated: true, isAdminCart,
          isAdminCurrency, isAdminPrice, isAdminAvailability, isAdminProduct,
          isAdminUser, isAdminOrder}));
    } else {
      dispatch(setCurrentAuthData({userInfo: null, idToken: null, accessToken: null, authenticated: false, isAdminCart: false, isAdminCurrency: false, isAdminPrice: false, isAdminAvailability: false, isAdminProduct: false,
          isAdminUser: false, isAdminOrder: false}));
    }
  }, [auth]);

  useEffect(() => {
    document.title  = t('main.mainPage.mainPageTitle');
     checkUser();
  }, [checkUser]);

  const logout = () => {
    auth?.signOut();
  };

  const popover = (
      <Popover id="popover-main">
        <Popover.Header as="h3" className="popover-basic"><>{t('main.mainPage.popover-main.title')}</></Popover.Header>
        <Popover.Body className="popover-basic">
          <>{t('main.mainPage.popover-main.popoverText')}</>
        </Popover.Body>
      </Popover>
  );

  const CartsMessage = () => (
      isAdminCart ?
          <Button variant="link" href={conf.urls.backoffice_cart}><>{t('main.mainPage.cartsMessage.buttonLink')}</></Button> :
          <OverlayTrigger trigger="focus" placement="right"
                          overlay={<>this.popover</>}>
            <Button variant="link"><>{t('main.mainPage.cartsMessage.buttonLink')}</></Button>
          </OverlayTrigger>
  );

  const CurrenciesMessage = () => (
      isAdminCurrency ?
          <><Button variant="link" href={conf.urls.backoffice_currency}><>{t('main.mainPage.currenciesMessage.buttonLink')}</></Button></> :

          <OverlayTrigger trigger="focus" placement="right"
                          overlay={<>this.popover</>}>
            <><Button variant="link"> <>{t('main.mainPage.currenciesMessage.overlayTrigger.link')}</></Button></>
          </OverlayTrigger>
  );


  const PricesMessage = () => (
      isAdminPrice ?
          <Button variant="link" href={conf.urls.backoffice_price}><>{t('main.mainPage.priceMessage.buttonLink')}</></Button> :
          <OverlayTrigger trigger="focus" placement="right"
                          overlay={<>this.popover</>}>
            <Button variant="link"><>{t('main.mainPage.priceMessage.overlayTrigger.link')}</></Button>
          </OverlayTrigger>
  );

  const AvailabilitiesMessage = () => (
      isAdminAvailability ?
          <Button variant="link" href={conf.urls.backoffice_availability}><>{t('main.mainPage.availabilitiesMessage.buttonLink')}</></Button> :
          <OverlayTrigger trigger="focus" placement="right"
                          overlay={<>this.popover</>}>
            <Button variant="link"> <>{t('main.mainPage.availabilitiesMessage.overlayTrigger.link')}</> </Button>
          </OverlayTrigger>
  );

  const ProductsMessage = () => (
      isAdminProduct ?
          <Button variant="link" href=''> <>{t('main.mainPage.productMessage.buttonLink')}</> </Button> :
          <OverlayTrigger trigger="focus" placement="right"
                          overlay={<>this.popover</>}>
            <Button variant="link"><>{t('main.mainPage.productMessage.overlayTrigger.link')}</></Button>
          </OverlayTrigger>
  );

  const UsersMessage = () => (
      isAdminUser ?
          <Button variant="link" href=''><>{t('main.mainPage.userMessage.buttonLink')}</></Button> :
          <OverlayTrigger trigger="focus" placement="right"
                          overlay={<>this.popover</>}>
            <Button variant="link"><>{t('main.mainPage.userMessage.overlayTrigger.link')}</></Button>
          </OverlayTrigger>
  );

  const OrdersMessage = () => (
      isAdminOrder ?
          <Button variant="link" href={conf.urls.backoffice_order}><>{t('main.mainPage.orderMessage.buttonLink')}</></Button> :
          <OverlayTrigger trigger="focus" placement="right"
                          overlay={<>this.popover</>}>
            <Button variant="link"><>{t('main.mainPage.orderMessage.overlayTrigger.link')}</></Button>
          </OverlayTrigger>
  );

  const toggleCollapse = () => {
    setCollapse(!collapse);
  }

  return (
      <Router>
        <div className="backofficeContent">

          <Header logout={logout}/>

          {authenticated ? (
                  (isAdminCart || isAdminCurrency || isAdminPrice
                      || isAdminAvailability || isAdminProduct
                      || isAdminUser || isAdminOrder) ?
                      (
                          <div className="mainPage" text-center="true">

                            <Row>
                              <Col md={2}
                                   className={collapse ? "verticalMenu collapsed"
                                       : "verticalMenu"}>
                                <VerticalMenu toggleCollapse={toggleCollapse}/>
                              </Col>
                              <Col md={10}>
                                <Route exact path="/" component={Body}/>
                                <Route exact path="/cart" component={Carts}/>
                                <Route exact path="/currency"
                                       component={Currencies}/>
                                <Route exact path="/price" component={Prices}/>
                                <Route exact path="/availability"
                                       component={Availability}/>
                                <Route exact path="/order" component={Orders}/>
                              </Col>
                            </Row>
                          </div>
                      )
                      :
                      <div className='messageLogout'>
                        <>{t('main.mainPage.messageLogout.messageText')}</>
                      </div>
              )
              :
              <div className='spinner'>
                <Spinner animation="border" role="status" size="sm"/>{' '}Authenticating
              </div>
          }
        </div>
        <ToastContainer/>
      </Router>
  );
}

export default MainPage;