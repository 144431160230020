import React, {ChangeEvent, MouseEvent, useState} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {useTranslation, Trans} from 'react-i18next';
import {AvailabilityExtendedDto} from "./Availabilities";

export interface ExistingAvailabilityProps {
  availability: AvailabilityExtendedDto,
  handleDelete: (e: MouseEvent) => void,
  handleUpdate: (e: MouseEvent) => void,
  handleQuantity: (e: ChangeEvent<HTMLInputElement>) => void,
  handleStatus: (e: ChangeEvent<HTMLSelectElement>) => void,
  handleReplenishmentTime: (e: ChangeEvent<HTMLInputElement>) => void,
}

const ExistingAvailability = ({availability, handleDelete, handleUpdate, handleQuantity, handleStatus, handleReplenishmentTime}: ExistingAvailabilityProps) => {

  const { t } = useTranslation();
  const [stateMachineAvailabilityStatus, setStateMachineAvailabilityStatus] = useState(
      {
        ALWAYS_AVAILABLE: {
          'NOT_AVAILABLE': 'availability.status.NOT_AVAILABLE',
          'IN_STOCK': 'availability.status.IN_STOCK'
        },
        NOT_AVAILABLE: {
          'ALWAYS_AVAILABLE': 'availability.status.ALWAYS_AVAILABLE',
          'IN_STOCK': 'availability.status.IN_STOCK'
        },
        IN_STOCK: {
          'ALWAYS_AVAILABLE': 'availability.status.ALWAYS_AVAILABLE',
          'NOT_AVAILABLE': 'availability.status.NOT_AVAILABLE'
        }
      }
  );

  const [availabilityStatusValueKeyTranslationObj, setAvailabilityStatusValueKeyTranslationObj] = useState({
        ALWAYS_AVAILABLE: 'availability.status.ALWAYS_AVAILABLE',
        NOT_AVAILABLE: 'availability.status.NOT_AVAILABLE',
        IN_STOCK: 'availability.status.IN_STOCK'
      });

  return (
    <Row className="tableContent">
      <Col xs={{span: 2}} className="value">
        {availability.articleId}
      </Col>
      <Col xs={{span: 2}} className="value">
        <input className="minQuantity" name="minQuantity"
               value={availability.quantity}
               onChange={handleQuantity}/>
      </Col>

      <Col xs={{span: 3}} className="value">
        <select
            className="currencyId"
            name="status" onChange={handleStatus}
            value={availability?.status?.toString()}>
          <option
              defaultValue={availability?.status?.toString()}><>{t(availabilityStatusValueKeyTranslationObj[availability.status!])}</></option>
          <option disabled>-----------------</option>
          <option value={Object.keys(
              stateMachineAvailabilityStatus[availability.status!])[0] as string}><>{t(Object.values(
              stateMachineAvailabilityStatus[availability.status!])[0])}</></option>
          <option value={Object.keys(
              stateMachineAvailabilityStatus[availability.status!])[1] as string}><>{t(Object.values(
              stateMachineAvailabilityStatus[availability.status!])[1])}</></option>

        </select>
      </Col>
      <Col xs={{span: 3}} className="value">
        <input className="replenishmentTime" name="replenishmentTime"
               value={availability.replenishmentTime}
               onChange={handleReplenishmentTime}/>
      </Col>
      <Col xs={1} className="value">
        <Button className="function-button" onClick={(e) => {
          handleUpdate(e);
        }}>
          <img src="/price-icons/arrow-repeat.svg"
               style={{width: 12, height: 12}} alt='update'/>
          <p><Trans
              i18nKey={'availability.existingAvailability.existingAvailability.update'}/>
          </p>
        </Button>
      </Col>
      <Col xs={1} className="value">
        <Button className="function-button" onClick={(e) => {
          handleDelete(e);
        }}>
          <img src="/price-icons/x-circle.svg"
               style={{width: 12, height: 12}} alt='delete'/>
          <p><Trans
              i18nKey={'availability.existingAvailability.existingAvailability.delete'}/>
          </p>
        </Button>
      </Col>
    </Row>
);

}

export default ExistingAvailability;
