import React, {ChangeEvent, useState} from 'react';
import conf from '../config';
import {Button, Col, Row} from "react-bootstrap";
import util from "../util";
import {Trans} from 'react-i18next';
import {CurrencyDto} from "../generated/api/priceApi";
import {AxiosResponse} from "axios";

export interface NewCurrencyProps {
  accessToken: string | null | undefined,
  currentPage: number,
  updateCurrencyListElements: (pageNumber: number) => void
}

const NewCurrency = ({accessToken, currentPage, updateCurrencyListElements} : NewCurrencyProps) => {

  const [newCurrency, setNewCurrency] = useState<CurrencyDto>({
      isoCode: '',
      name: '',
      symbol: ''
  })

  const handleAddCurrency = async () => {
    let data = newCurrency;

    util.serviceCallWrapper({
          method: 'POST',
          url: conf.urls.priceService + '/currencies',
          data: data,
          headers: {Authorization: `Bearer ${accessToken}`}
        },
        (result: AxiosResponse) => {
          if (result.status === 201) {
            setNewCurrency({
              isoCode: '',
              name: '',
              symbol: ''
            });
          }
          updateCurrencyListElements(currentPage)
        },
        {
          201: {
            'SUCCESS': 'Currency ' + data.isoCode + ' is created.'
          }
        },
        () => {
          setNewCurrency({
            isoCode: '',
            name: '',
            symbol: ''
          });
        },
        true
    );
  }

  const addCurrencyAndUpdateCurrencyList = () => {
    handleAddCurrency().then(
        () => updateCurrencyListElements(currentPage));
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setNewCurrency(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  return (
      <div className="newCurrency">

        <Row className="tableHeader">
          <Col xs={3} className="label">
            <Trans
                i18nKey={'currency.newCurrency.newCurrency.isoCode.label'}/>
          </Col>
          <Col xs={3} className="label">
            <Trans
                i18nKey={'currency.newCurrency.newCurrency.name.label'}/>
          </Col>
          <Col xs={3} className="label">
            <Trans
                i18nKey={'currency.newCurrency.newCurrency.symbol.label'}/>
          </Col>
          <Col xs={3} className="label"/>
        </Row>

        <Row className="tableContent">
          <Col xs={3} className="value">
            <input className="isoCodeNewCurrency" name="isoCode"
                   value={newCurrency.isoCode}
                   onChange={handleInputChange}/>
          </Col>
          <Col xs={3} className="value">
            <input className="nameNewCurrency" name="name"
                   value={newCurrency.name}
                   onChange={handleInputChange}/>
          </Col>
          <Col xs={3} className="value">
            <input className="symbolNewCurrency" name="symbol"
                   value={newCurrency.symbol}
                   onChange={handleInputChange}/>
          </Col>
          <Col xs={3} className="value">
            <Button className="function-button addNewCurrency"
                    onClick={() => {
                      addCurrencyAndUpdateCurrencyList()
                    }}>
              <img src="/price-icons/plus-circle.svg"
                   style={{width: 13, height: 13}} alt='Add New'/>
              <p><Trans
                  i18nKey={'currency.newCurrency.newCurrency.addNew.value'}/>
              </p>
            </Button>
          </Col>

        </Row>
        <Row>
          <Col xs={12} className="divider"> {""}</Col>
        </Row>
      </div>
  );
}

export default NewCurrency;
