import React from 'react';
import {FormText, Image, Row, Col} from "react-bootstrap";
import {Trans} from 'react-i18next';

const Body = () => {

    return (
        <div className="mainViewBody">
          <Row>
            <Col md={1}>
            </Col>
            <Col md={3}>
              <FormText className="heading">
                <Trans
                    i18nKey="main.body.mainViewBody.headingText" // optional -> fallbacks to defaults if not provided
                    defaults="Home - Dashboard" // optional defaultValue
                    components={{tag: <span/>}}
                />

               </FormText>
            </Col>
            <Col md={8}>
            </Col>
          </Row>

          <Row>
            <Col md={1}>
            </Col>
            <Col md={3}>
              <Image src="/dashboard-icons/total-views.png" fluid/>
            </Col>
            <Col md={3}>
              <Image src="/dashboard-icons/total-sales.png" fluid/>
            </Col>
            <Col md={3}>
              <Image src="/dashboard-icons/total-earnings.png" fluid/>
            </Col>
            <Col md={1}>
            </Col>
          </Row>

          <Row>
            <Col md={1}>
            </Col>
            <Col md={9}>
              <Image src="/dashboard-icons/statistics.png" fluid/>
            </Col>
            <Col md={2}>
            </Col>
          </Row>

          <Row>
            <Col md={1}>
            </Col>
            <Col md={6}>
              <Image src="/dashboard-icons/referrer.png" fluid/>
            </Col>
            <Col md={3}>
              <Image src="/dashboard-icons/details-on-map.png" fluid/>
            </Col>
            <Col md={2}>
            </Col>
          </Row>

          <Row>
            <Col md={1}>
            </Col>
            <Col md={6}>
              <Image src="/dashboard-icons/top-product.png" fluid/>
            </Col>
            <Col md={3}>
              <Image src="/dashboard-icons/quick-details.png" fluid/>
            </Col>
            <Col md={2}>
            </Col>
          </Row>

        </div>
    );
}

export default Body;