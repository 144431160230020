import React, {ChangeEvent, useState} from 'react';
import conf from '../config';
import util from "../util";
import {Button, Col, Row} from "react-bootstrap";
import {useTranslation, Trans} from 'react-i18next';
import {AvailabilityDto} from "../generated/api/availabilityApi";
import {AxiosResponse} from "axios";

export interface NewAvailabilityProps {
  accessToken: string | null | undefined,
  currentPage: number,
  updateAvailabilityListElements: (pageNumber: number) => void
}

const NewAvailability = ({accessToken, currentPage, updateAvailabilityListElements} : NewAvailabilityProps) => {

  const { t } = useTranslation();
  const [newAvailability, setNewAvailability] = useState<AvailabilityDto>({
    articleId: '',
    quantity: 0,
    status: 'ALWAYS_AVAILABLE',
    replenishmentTime: 0,
  });

  const handleAddAvailability = async () => {
    let data = newAvailability;

    util.serviceCallWrapper({
          method: 'POST',
          url: conf.urls.availabilityService,
          data: data,
          headers: {Authorization: `Bearer ${accessToken}`}
        },
        (result: AxiosResponse) => {
          if (result.status === 201) {
            setNewAvailability(prevState => ({
              ...prevState,
              articleId: '',
              quantity: 0,
              replenishmentTime: 0,
            }));
          }
          updateAvailabilityListElements(currentPage)
        },
        {
          201: {
            'SUCCESS': 'Availability for item ' + data.articleId
                + ' is created.'
          },
          409: {
            'ERROR': 'Availability for item ' + data.articleId
                + ' already exists!'
          },
          422: {
            'ERROR': 'Mandatory data to create the availability of the item '
                + data.articleId + ' is not present!'
          },
        },
        () => {
        },
        true
    );
  }

  const addAvailabilityAndUpdateAvailabilityList = () => {
    handleAddAvailability().then(() => updateAvailabilityListElements(currentPage));
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setNewAvailability(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

    return (
        <div className="newAvailability">

          <Row className="tableHeader">
            <Col xs={{span: 2}} className="label">
              <Trans
                  i18nKey={'availability.newAvailability.newAvailability.articleNumber.label'}/>
            </Col>
            <Col xs={{span: 2}} className="label">
              <Trans
                  i18nKey={'availability.newAvailability.newAvailability.quantity.label'}/>
            </Col>
            <Col xs={{span: 3}} className="label">

              <Trans
                  i18nKey={'availability.newAvailability.newAvailability.status.label'}/>
            </Col>
            <Col xs={{span: 3}} className="label">

              <Trans
                  i18nKey={'availability.newAvailability.newAvailability.replenishmentTime.label'}/>
            </Col>
            <Col xs={{span: 2}} className="label"/>
          </Row>
          <Row className="tableContent">
            <Col xs={{span: 2}} className="value">
              <input className="articleIdNew" name="articleId"
                     value={newAvailability.articleId}
                     onChange={handleInputChange}/>
            </Col>
            <Col xs={{span: 2}} className="value">
              <input className="minQuantity" name="quantity"
                     value={newAvailability.quantity}
                     onChange={handleInputChange}/>
            </Col>
            <Col xs={{span: 3}} className="value">
              <select
                  name="status" onChange={handleInputChange}>
                <option value="Select Status"><>{t('availability.status.selectStatus')}</></option>
                <option disabled>-----------------</option>
                <option value="ALWAYS_AVAILABLE"><>{t('availability.status.ALWAYS_AVAILABLE')}</></option>
                <option value="NOT_AVAILABLE"><>{t('availability.status.NOT_AVAILABLE')}</></option>
                <option value="IN_STOCK"><>{t('availability.status.IN_STOCK')}</></option>
              </select>
            </Col>
            <Col xs={{span: 3}} className="value">
              <input className="replenishmentTime"
                     name="replenishmentTime"
                     value={newAvailability.replenishmentTime}
                     onChange={handleInputChange}/>
            </Col>
            <Col xs={{span: 2}} className="value">
              <Button className="function-button addNewPrice" onClick={() => {
                addAvailabilityAndUpdateAvailabilityList();
              }}>
                <img src="/price-icons/plus-circle.svg"
                     style={{width: 13, height: 13}} alt='delete'/>
                <p><Trans
                    i18nKey={'availability.newAvailability.newAvailability.addNew.value'}/>
                </p>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="divider"> {""}</Col>
          </Row>
        </div>

    );

}

export default NewAvailability;
