import React, {useCallback, useState} from "react";
import {Container, Row, Col, Button, Modal} from "react-bootstrap";
import OrderItem from "./OrderItem";
import UserInfo from "./UserInfo";
import ShippingAddress from "./ShippingAddress";
import conf from "../config";
import util, {UuidPopover} from "../util";

import {useTranslation} from 'react-i18next';
import {CurrencyDto} from "../generated/api/priceApi";
import {OrderDto, OrderEntryDto} from "../generated/api/orderApi";
import {AxiosResponse} from "axios";

export interface ExistingOrderProps {
  accessToken: string | null | undefined,
  order: OrderDto,
}

const ExistingOrder = ({accessToken, order} : ExistingOrderProps) => {

  const {t} = useTranslation();
  const [showInfo, setShowInfo] = useState(false);
  const [currency, setCurrency] = useState<CurrencyDto | undefined>();

  const loadCurrency = useCallback(() => {
    util.serviceCallWrapper({
          method: "GET",
          url: conf.urls.priceService + "/currencies/" + order.currencyId,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          }
        },
        (result: AxiosResponse) => {
          setCurrency(result.data);
        },
        {},
        () => {
        },
        false
    );
  }, [accessToken, order]);

  const handleClose = () => setShowInfo(false);

  const handleShow = () => {
    setShowInfo(true);
    if (!currency) {
      loadCurrency();
    }
  }

  const renderOrderItem = (item: OrderEntryDto) => {
    return (<OrderItem key={item.articleId} item={item} currency={currency}/>);
  };

  return (

      <div>
        <Row className="tableContent">
          <Col xs={2} className="value">
            <Row>
              <UuidPopover longId={order.id} shortId={order.orderNum}/>
            </Row>
          </Col>
          <Col xs={2} className="value">
            {util.displayTime(order.createTime, conf.formatting.dateTime)}
          </Col>
          <Col xs={1} className="value">
            {order.orderStatus}
          </Col>
          <Col xs={2} className="value">
            {order.user ? order.user.firstName + " " + order.user.lastName
                : 'Guest user'}
          </Col>
          <Col xs={1} className="value">
            {order.entries !== null ? order?.entries?.length : "-"}
          </Col>
          <Col xs={1} className="value">
            -
          </Col>
          <Col xs={1} className="value">
            -
          </Col>
          <Col xl={1} className="d-none d-xl-block value"/>
          <Col xs={2} xl={1} className="value">
            <Button className="function-button" onClick={handleShow}>
              <img src="/dashboard-icons/pencil-square.svg"
                   style={{width: 12, height: 12}} alt='pencil-square'/>
              <p> <>{t('order.existingOrder.orderModal.value')}</> </p>

            </Button>
          </Col>
          <Col xs={2}>
          </Col>
        </Row>

        <Modal show={showInfo} onHide={handleClose} size="lg"
               dialogClassName="modal-orderInfo">
          <Modal.Header closeButton>
            <Modal.Title className="content"> <>{t(
                'order.existingOrder.orderMainTitle.order')}: <span
                className="value">{order.id}</span></> </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container className="additionalOrderInfo">
              <Row className="content">
                <Col> <>{t('order.existingOrder.orderMainTitle.date')}: <span
                    className="values">
                  {util.displayTime(order.createTime, conf.formatting.dateTime)}</span></>
                </Col>
              </Row>
              <Row className="content">
                <Col> <>{t(
                    'order.existingOrder.orderMainTitle.items')}: {order.entries
                    ? order.entries.map(
                        (item) => renderOrderItem(item)) : '/'}</> </Col>
              </Row>
              <Row className="content">
                <Col> <>{t(
                    'order.existingOrder.orderMainTitle.user')}:{order.user
                    ? <UserInfo user={order.user}/> : <span
                        className="values">{order.userId}</span>}</> </Col>
              </Row>
              <Row className="content">
                <Col> <>{t(
                    'order.existingOrder.orderMainTitle.shippingAddress')}:{order.shippingAddress
                    ? <ShippingAddress address={order.shippingAddress}/> : <span
                        className="values">Unknown</span>}</> </Col>
              </Row>
              <Row className="content">
                <Col> <>{t(
                    'order.existingOrder.orderMainTitle.orderStatus')}: <span
                    className="values">{order.orderStatus}</span></> </Col>
              </Row>
              <Row className="content">
                <Col> <>{t(
                    'order.existingOrder.orderMainTitle.shipmentStatus')}: <span
                    className="values">{order.shipmentStatus
                    ? order.shipmentStatus : "Not sent"}</span></> </Col>
              </Row>
              <Row className="content">
                <Col> <>{t(
                    'order.existingOrder.orderMainTitle.paymentStatus')}: <span
                    className="values">{order.paymentStatus
                    ? order.paymentStatus : "Not paid"}</span></> </Col>
              </Row>
              <Row className="content">
                <Col> <>{t('order.existingOrder.orderMainTitle.charges')}: <span
                    className="values"><>{order.charges ? order.charges
                    : "-"}</></span></> </Col>
              </Row>
              <Row className="content">
                <Col> <>{t('order.existingOrder.orderMainTitle.total')}: <span
                    className="values">{util.displayPrice(order.totalPrice,
                    currency)}</span></> </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button className="closeView" variant="secondary"
                    onClick={handleClose}>
              <>{t('order.existingOrder.orderMainTitle.close')}</>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
  );

};

export default ExistingOrder;
