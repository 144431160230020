import React, {MouseEvent, useState} from "react";
import {
  Button,
  Col,
  Container,
  Modal,
  Row
} from "react-bootstrap";
import UserInfo from "./UserInfo";
import conf from "../config";
import util, {UuidPopover} from "../util";
import Item from "./Item";
import {Trans} from 'react-i18next';
import {CartDto, ItemDto} from "../generated/api/cartOrchestrationApi";
import {CurrencyDto} from "../generated/api/priceApi";
import {AxiosResponse} from "axios";
import {UserDto} from "../generated/api/userApi";

export interface BackOfficeCartProps {
  cart: CartDto,
  currencies: CurrencyDto[],
  accessToken: string | null | undefined,
  handleDelete: (e: MouseEvent) => void
}

const BackofficeCart = ({cart, accessToken, currencies, handleDelete} : BackOfficeCartProps) => {

  const [user, setUser] = useState<UserDto | undefined>(undefined);
  const [items, setItems] =  useState<ItemDto[]>([]);
  const [showInfo, setShowInfo] = useState(false);
  const [total, setTotal] = useState<number | undefined>(undefined);
  const [currency, setCurrency] = useState<CurrencyDto | undefined>(undefined);

  const handleClose = () => {
    setShowInfo(false);
  }

  const fetchUser = () => {
    util.serviceCallWrapper({
          method: "GET",
          url: conf.urls.userService + "/" + cart.userId,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          }
        },
        (result: AxiosResponse) => {
          setUser(result.data);
        },
        {},
        () => {
        },
        false
    );
  };

  const handleShow = () => {
    setShowInfo(true);
    fetchUser();
    util.serviceCallWrapper({
          method: 'GET',
          url: conf.urls.cartOrchestration + '/' + cart.id,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
        (result: AxiosResponse) => {
          let items: ItemDto[] = result.data.items.map((itemIn: ItemDto) => {
            return {
              key: Math.random() * 100,
              id: itemIn.id,
              articleId: itemIn.articleId,
              availability: itemIn.availability,
              name: itemIn.name,
              orderable: itemIn.orderable,
              price: itemIn.price,
              unitPrice: itemIn.unitPrice,
              quantity: itemIn.quantity,
            };
          });
          setItems(items);
          setTotal(result.data.total);
        },
        {},
        () => {
        },
        false
    );
    const currency = currencies ? currencies.find(
        currency => currency.id === cart.currencyId) : undefined;
    setCurrency(currency);
  }

  const renderItem = (item: ItemDto, currency: CurrencyDto | undefined) => {
  return (<Item key={item.articleId} item={item} currency={currency}/>);
}

  return (
      <Row className="tableContent">
        <Col xs={2} className="value">
          <Row>
            <UuidPopover longId={cart.id}
                         shortId={cart?.id?.split(
                             "-")[cart?.id?.split("-").length - 1]}/>
          </Row>
        </Col>
        <Col xs={2} className="value">
          {util.displayTime(undefined, conf.formatting.dateTime)}
        </Col>

        <Col xs={3} className="value">
          {/*TODO this logic is incorrect, fix it, for now just write Guest customer*/}
          {/*TODO: Tickets: velox-shop/user#9, velox-shop/backoffice-ui#11*/}
          {/*{customer ? cart.user.firstName + " "*/}
          {/*    + cart.user.lastName : 'Guest customer'}*/}
          {'Guest user'}
        </Col>
        <Col xs={3} className="value">
          {cart.items !== null ? cart?.items?.length
              : "-"}
        </Col>
        <Col xs={1} className="value">
          <Button className="function-button"
                  onClick={handleShow}><Trans
              i18nKey={'cart.backofficeCart.showCart.value'}/>
          </Button>
        </Col>
        <Col xs={1} className="value">
          <Button className="function-button"
                  onClick={handleDelete}><Trans
              i18nKey={'cart.backofficeCart.deleteCart.value'}/></Button>
        </Col>
        <Modal show={showInfo} onHide={handleClose} size="lg"
               dialogClassName="modal-orderInfo">
          <Modal.Header closeButton>
            <Modal.Title className="content"> <Trans
                i18nKey={'cart.backofficeCart.modalHeader.content.value'}/> : <span
                className="value">{cart.id}</span></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container className="additionalOrderInfo">
              <Row className="content">
                <Col> <Trans
                    i18nKey={'cart.backofficeCart.modalBody.content.date'}/> : <span
                    className="values">
                  {/*TODO set undefined till we get correct createTime from backend, this will always return current time for now*/}
                  {/*TODO Ticket: velox-shop/cart#17, velox-shop/cart-orchestration#18*/}
                  {util.displayTime(undefined, conf.formatting.dateTime)}
                </span></Col>
              </Row>
              <Row className="content">
                <Col> <Trans
                    i18nKey={'cart.backofficeCart.modalBody.content.items'}/> : {items.length
                > 0
                    ? items.map(
                        (item) => renderItem(item, currency))
                    : '/'}</Col>
              </Row>
              <Row className="content">
                <Col><Trans
                    i18nKey={'cart.backofficeCart.modalBody.content.user'}/> : {user
                    ? <UserInfo
                        user={user}/> : <span
                        className="values">{cart.userId}</span>}
                </Col>
              </Row>
              <Row className="content">
                <Col> <Trans
                    i18nKey={'cart.backofficeCart.modalBody.content.total'}/> : <span
                    className="values">{total
                    ? util.displayPrice(total, currency)
                    : "-"}</span></Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button className="closeView" variant="secondary"
                    onClick={handleClose}>
              <Trans
                  i18nKey={'cart.backofficeCart.modalFooter.close'}/>
            </Button>
          </Modal.Footer>
        </Modal>

      </Row>
  );
};

export default BackofficeCart;