import React, {useState, useEffect, useCallback, MouseEvent, ChangeEvent} from 'react';
import conf from '../config';
import {useSelector} from 'react-redux';
import {
  Button,
  Col,
  Container,
  Pagination,
  Row,
  Spinner
} from 'react-bootstrap';
import NewAvailability from "./NewAvailability";
import ExistingAvailability from "./ExistingAvailability";
import util from "../util";
import {useTranslation} from 'react-i18next';
import {Trans} from 'react-i18next';
import {Token} from "../types/token";
import {AvailabilityDto} from "../generated/api/availabilityApi";
import {AxiosResponse} from "axios";

// had to introduce AvailabilityExtendedDto since AvailabilityDto id is always same when returned from db
// therefore the component does not get the new unique key after updating some field, for example, quantity
// and after update all input fields on Availability page freeze
// we introduce key field that will keep unique key of ExistingAvailability component till next update/fetch of Availabilities occurs
export interface AvailabilityExtendedDto extends AvailabilityDto {
  key: number
}

const Availabilities : React.FC = () => {

  const {t} = useTranslation();
  const [availabilities, setAvailabilities] = useState<AvailabilityExtendedDto[]>([]);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [addNewFlag, setAddNewFlag] = useState(false);
  const [paginationItems, setPaginationItems] = useState<Element[]>([]);
  const [checkedButton, setCheckedButton] = useState("active");
  const [isMounted, setIsMounted] = useState(true);
  const token: Token = useSelector((state: any) => state.addAuthData);


  const updateAvailabilityListElements = useCallback((pageNumber: number) => {
    util.serviceCallWrapper({
          method: "GET",
          url: conf.urls.availabilityService + "?page=" + pageNumber
              + "&size=10",
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          }
        },
        (result: AxiosResponse) => {
          let availabilities: AvailabilityExtendedDto[] = result.data.content.map((availabilityIn: AvailabilityDto) => {
            return {
              key: Math.random() * 100,
              id: availabilityIn.id,
              articleId: availabilityIn.articleId,
              quantity: availabilityIn.quantity,
              status: availabilityIn.status,
              replenishmentTime: availabilityIn.replenishmentTime
            }
          });
          // check if the component is mounted
          // if(isMounted){
          setAvailabilities(availabilities);
          setTotalPages(result.data.totalPages - 1);
          // }
        },
        {},
        () => {
        },
        false
    );
  }, [token
    // , isMounted
  ]);

  const openPage = useCallback((pageNumber: number) => {
    updateAvailabilityListElements(pageNumber);
    setCurrentPage(pageNumber);
  }, [updateAvailabilityListElements, setCurrentPage]);

  const createPagination = useCallback(() => {
    let pages: any[] = [];
    for (let number = 0; number <= totalPages; number++) {
      pages.push(
          <Pagination.Item key={number} active={number === currentPage}
                           onClick={() => openPage(number)}>
            {number + 1}
          </Pagination.Item>,
      );
      setPaginationItems(pages);
    }
  }, [openPage, currentPage, totalPages]);

  useEffect(() => {
    document.title  = t('availability.availabilityMain.availabilityMainTitle');
    updateAvailabilityListElements(currentPage);
    createPagination();
    return () => {
      setIsMounted(false)
    };
  }, [updateAvailabilityListElements, createPagination, currentPage,
    token, isMounted]);

  const handleDeleteClick = (event: MouseEvent, availability: AvailabilityExtendedDto) => {
    util.serviceCallWrapper({
          method: 'DELETE',
          url: conf.urls.availabilityService + '/' + availability.id,
          headers: {Authorization: `Bearer ${token.accessToken}`},
        },
        () => updateAvailabilityListElements(currentPage),
        {
          204: {
            'SUCCESS': 'Availability for item ' + availability.articleId
                + ' is deleted.'
          },
          404: {
            'ERROR': 'Availability for item ' + availability.articleId
                + ' to be deleted is not found!'
          }
        },
        () => {
        },
        true
    );
  };

  const handleUpdateClick = (event: MouseEvent, availability: AvailabilityExtendedDto) => {
    let updatedAvailabilities = availabilities.slice();

    updatedAvailabilities.forEach((i) => {
      if (i.id === availability.id) {

        util.serviceCallWrapper({
              method: 'PATCH',
              url: conf.urls.availabilityService + '/' + i.id,
              data: i,
              headers: {Authorization: `Bearer ${token.accessToken}`}
            },
            () => {
              updateAvailabilityListElements(currentPage)
            },
            {
              200: {
                'SUCCESS': 'Availability for item ' + i.articleId
                    + ' is updated.'
              },
              404: {
                'ERROR': 'Availability for item ' + i.articleId
                    + ' to be updated is not found!'
              },
              409: {
                'ERROR': 'Id/articledId of availability for item ' + i.articleId
                    + ' cannot be changed!'
              },
              422: {
                'ERROR': 'Mandatory data to update the availability of item '
                    + i.articleId + ' is not present!'
              },
            },
            () => {
            },
            true
        );
      }
    });
  };

  const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>, availability: AvailabilityExtendedDto) => {
    let updatedAvailabilities = availabilities.slice();
    updatedAvailabilities.map((i) => {
      if (i.id === availability.id) {
        return Object.assign(i, {quantity: Number(event.target.value)});
      }
      return i;
    });
    setAvailabilities(updatedAvailabilities);
  };

  const handleStatusChange = (event: ChangeEvent<HTMLSelectElement>, availability: AvailabilityExtendedDto) => {
    let updatedAvailabilities = availabilities.slice();
    updatedAvailabilities.map((i) => {
      if (i.id === availability.id) {
        return Object.assign(i, {status: event.target.value});
      }
      return i;
    });
    setAvailabilities(updatedAvailabilities);
  };

  const handleReplenishmentTimeChange = (event: ChangeEvent<HTMLInputElement>, availability: AvailabilityExtendedDto) => {
    let updatedAvailabilities = availabilities.slice();
    updatedAvailabilities.map((i) => {
      if (i.id === availability.id) {
        return Object.assign(i,
            {replenishmentTime: Number(event.target.value)});
      }
      return i;
    });
    setAvailabilities(updatedAvailabilities);
  };

  const openFirstPage = () => {
    openPage(0);
  };

  const openLastPage = () => {
    openPage(totalPages);
  };

  const openNextPage = () => {
    if (currentPage < totalPages) {
      openPage(currentPage + 1);
    }
  };

  const openPreviousPage = () => {
    if (currentPage > 0) {
      openPage(currentPage - 1);
    }
  };

  // method for search
  const handleReturnAvailabilityByArticleId = useCallback(() => {
    if (search !== '') {
      util.serviceCallWrapper({
            method: "GET",
            url: conf.urls.availabilityService + '?articleId=' + search,
            headers: {
              Authorization: `Bearer ${token.accessToken}`,
            }
          },
          (result: AxiosResponse) => {
            let availabilities: AvailabilityExtendedDto[] = result.data.content.map((availabilityIn: AvailabilityDto) => {
              return {
                id: availabilityIn.id,
                articleId: availabilityIn.articleId,
                quantity: availabilityIn.quantity,
                status: availabilityIn.status,
                replenishmentTime: availabilityIn.replenishmentTime,
              }
            });
            setAvailabilities(availabilities);
            setCurrentPage(0);
            setTotalPages(0);
          },
          {
            200: {
              'SUCCESS': 'Availability for item ' + search + ' is retrieved.'
            },
            404: {
              'ERROR': 'Availability for item ' + search + ' is not found!'
            },
            422: {
              'ERROR': 'Mandatory data to retrieve the availability of item '
                  + search + ' is not present!!'
            },
          },
          () => {},
          true
      );
    } else {
      updateAvailabilityListElements(currentPage);
    }
  }, [token, currentPage, search, updateAvailabilityListElements]);

  const toggleAddNewFlag = () => {
    setAddNewFlag(!addNewFlag);
  }

  const toggleCheckedButton = (value: string) : void => {
    setCheckedButton(value);
  }

  const renderAvailability = (availability: AvailabilityExtendedDto) => {
    return (
          <ExistingAvailability key={availability.key}
                                availability={availability}
                                handleDelete={(e: MouseEvent) => handleDeleteClick(e,
                                    availability)}
                                handleUpdate={(e: MouseEvent) => handleUpdateClick(e,
                                    availability)}
                                handleQuantity={(e: ChangeEvent<HTMLInputElement>) => handleQuantityChange(e,
                                    availability)}
                                handleStatus={(e: ChangeEvent<HTMLSelectElement>) => handleStatusChange(e,
                                    availability)}
                                handleReplenishmentTime={(e: ChangeEvent<HTMLInputElement>) => handleReplenishmentTimeChange(
                                    e, availability)}
          />
    );
  }

  return (
      <Row>
        <Col xl={1} className="hidden-lg"/>
        <Col xl={10} className="mainContent">
          <Container fluid className="availability">
            {(token.authenticated) ? (
                    (token.isAdminAvailability) ?

                        <div>
                          <Row className="pageName">
                            <Col xs={{span: 12}} className="label">
                              <Trans
                                  i18nKey="availability.availability.availabilityMainLabel" // optional -> fallbacks to defaults if not provided
                                  defaults="Availabilities - Overview" // optional defaultValue
                                  components={{tag: <span/>}}
                              />

                            </Col>
                          </Row>

                          {addNewFlag ?
                              <div>
                                <Row className="backofficeTable">
                                  <Col xs={{offset: 10, span: 2}}
                                       className="features">
                                    <Button className="filter">
                                      <img src="/common-icons/search.svg"
                                           style={{width: 18, height: 18}}
                                           alt=''/>
                                      <span className="value"> <>{t(
                                          'availability.availability.backofficeTableFilter.value')}</>  </span>
                                    </Button>
                                    <Button className="addNew"
                                            onClick={() => (toggleAddNewFlag())}>
                                      <img src="/common-icons/plus-circle-black.svg"
                                           style={{width: 18, height: 18}}
                                           alt=''/>
                                      <span className="value"> <>{t(
                                          'availability.availability.backofficeTableAdd.value')}</>  </span>
                                    </Button>
                                  </Col>
                                </Row>
                                <NewAvailability
                                    updateAvailabilityListElements={updateAvailabilityListElements}
                                    accessToken={token.accessToken}
                                    currentPage={currentPage}/>
                                <Row className="backofficeTable">
                                  <Col xs={{span: 9}} className="tab">
                                    <Button className={checkedButton === "active"
                                        ? "button active" : "button"}
                                            onClick={() => {
                                              setCurrentPage(0);
                                              toggleCheckedButton("all")
                                            }}><>{t(
                                        'availability.availability.backofficeTableTab.all')}</></Button>
                                  </Col>
                                  <Col xs={{span: 3}} className="features"/>
                                </Row>
                              </div>
                              :
                              <Row className="backofficeTable">
                                <Col xs={{span: 9}} className="tab">
                                  <Button className={checkedButton === "active"
                                      ? "button active" : "button"} onClick={() => {
                                    setCurrentPage(0);
                                    toggleCheckedButton("active")
                                  }}><>{t(
                                      'availability.availability.backofficeTableTab.all')}</></Button>
                                </Col>
                                <Col xs={{span: 3}} className="features">
                                  <Button className="filter">
                                    <img src="/common-icons/search.svg"
                                         style={{width: 18, height: 18}}
                                         alt=''/>
                                    <span className="value"> <>{t(
                                        'availability.availability.backofficeTableFilter.value')}</> </span>
                                  </Button>
                                  <Button className="addNew"
                                          onClick={() => (toggleAddNewFlag())}>
                                    <img src="/common-icons/plus-circle-black.svg"
                                         style={{width: 18, height: 18}}
                                         alt=''/>
                                    <span className="value"> <>{t(
                                        'availability.availability.backofficeTableAdd.value')}</> </span>
                                  </Button>
                                </Col>
                              </Row>
                          }
                          <Row className="tableHeader">
                            <Col xs={{span: 2}} className="label">
                              <>{t('availability.availability.tableHeader.articleNumber.label')}</>
                            </Col>
                            <Col xs={{span: 2}} className="label">
                              <>{t('availability.availability.tableHeader.quantity.label')}</>
                            </Col>
                            <Col xs={{span: 3}} className="label">
                              <>{t('availability.availability.tableHeader.status.label')}</>
                            </Col>
                            <Col xs={{span: 3}} className="label">
                              <>{t('availability.availability.tableHeader.replenishmentTime.label')}</>
                            </Col>
                            <Col xs={{span: 2}} className="label"/>
                          </Row>
                          {availabilities.map(
                              (availability) => renderAvailability(availability))}
                          <Row className="paging">
                            <Col xs={5} className="content"/>
                            <Col xs={7} className="content">
                              <Pagination className="buttons">
                                <Pagination.First onClick={openFirstPage}/>
                                <Pagination.Prev onClick={openPreviousPage}/>
                                <Pagination><>{paginationItems}</></Pagination>
                                <Pagination.Next onClick={openNextPage}/>
                                <Pagination.Last onClick={openLastPage}/>
                              </Pagination>
                            </Col>
                          </Row>
                        </div>
                        :
                        <div className='messageLogout'>
                          <>{t('availability.availability.messageLogout.messageText')}</>
                        </div>
                )
                :
                <div className='spinner'>
                  <Spinner animation="border" role="status" size="sm"/>{" "}Authenticating
                </div>
            }
          </Container>
        </Col>
      </Row>
  );
};

export default Availabilities;