import React, {useState, useEffect, useCallback, ChangeEvent, MouseEvent} from 'react';
import conf from '../config';
import {
  Button,
  Col,
  Container,
  Pagination,
  Row,
  Spinner,
} from 'react-bootstrap';
import NewPrice from "./NewPrice";
import ExistingPrice from "./ExistingPrice";
import {useSelector} from "react-redux";
import util from "../util";
import { useTranslation } from 'react-i18next';
import {Trans} from 'react-i18next';
import {CurrencyDto, PriceDto} from "../generated/api/priceApi";
import {AxiosResponse} from "axios";
import {Token} from "../types/token";

const Prices : React.FC = () => {

  const { t } = useTranslation();
  const [prices, setPrices] = useState<PriceDto[]>([]);
  const [currencies, setCurrencies] = useState<CurrencyDto[]>([]);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [addNewFlag, setAddNewFlag] = useState(false);
  const [paginationItems, setPaginationItems] = useState<Element[]>([]);
  const [checkedButton, setCheckedButton] = useState("active");
  const [statusFilter, setStatusFilter] = useState("ACTIVE");
  const token: Token = useSelector((state: any) => state.addAuthData);

  const updatePriceListElements = useCallback((pageNumber: number) => {
    util.serviceCallWrapper({
      method: "GET",
      url: conf.urls.priceService + "/prices?page=" + pageNumber + "&size=10&statusFilter="+statusFilter,
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
      }
    },
    (result: AxiosResponse) => {
        let prices: PriceDto[] = result.data.content.map((priceIn: PriceDto) => {
        return {
          id: priceIn.id,
          articleId: priceIn.articleId,
          validFrom: priceIn?.validFrom?.substr(0, 10),
          validTo: priceIn?.validTo?.substr(0, 10),
          minQuantity: priceIn.minQuantity,
          unitPrice: priceIn.unitPrice,
          active: priceIn.active,
          currency: priceIn.currency
        }
      });
      setPrices(prices);
      setTotalPages(result.data.totalPages - 1);
      },
    {},
        () => {},
        false
    );
  }, [token, statusFilter])

  const openPage = useCallback((pageNumber: number) => {
    updatePriceListElements(pageNumber);
    setCurrentPage(pageNumber);
  }, [updatePriceListElements, setCurrentPage]);

  const createPagination = useCallback(() :void => {
    let pages: any[] = [];
    for (let number = 0; number <= totalPages; number++) {
      pages.push(
          <Pagination.Item key={number} active={number === currentPage}
                           onClick={() => openPage(number)}>
            {number + 1}
          </Pagination.Item>,
      );
      setPaginationItems(pages);
    }
  }, [openPage, currentPage, totalPages]);

  const loadCurrencies = useCallback(() => {
    util.serviceCallWrapper({
          method: "GET",
          url: conf.urls.priceService + "/currencies",
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          }
        },
        (result: AxiosResponse) => {
          let currencies: CurrencyDto[] = result.data.content.map((currencyIn: CurrencyDto) => {
            return {
              id: currencyIn.id,
              isoCode: currencyIn.isoCode,
              name: currencyIn.name,
              symbol: currencyIn.symbol,
            }
          });
          setCurrencies(currencies);
        },
        {},
        () => {},
        false
    );
  }, [token])

  useEffect(() => {
    document.title = t('price.priceMain.priceMainTitle');
    updatePriceListElements(currentPage);
    createPagination();
    loadCurrencies();
  }, [updatePriceListElements, createPagination, currentPage, token]);

  const openFirstPage = () => {
    openPage(0);
  };

  const openLastPage = () => {
    openPage(totalPages);
  };

  const openNextPage = () => {
    if (currentPage < totalPages) {
      openPage(currentPage + 1);
    }
  };

  const openPreviousPage = () => {
    if (currentPage > 0) {
      openPage(currentPage - 1);
    }
  };

  const handleDeleteClick = (event: MouseEvent, price: PriceDto) : void => {
    util.serviceCallWrapper({
      method: 'DELETE',
      url: conf.urls.priceService + '/prices/' + price.id,
      headers: {Authorization: `Bearer ${token.accessToken}`},
    },
        () => {updatePriceListElements(currentPage)},
{
            204: {
              'SUCCESS': 'Price for item ' + price.articleId + ' is deleted.'
            },
            404: {
              'ERROR': 'Price for item ' + price.articleId + ' to be deleted is not found!'
            },
          },
        () => {},
        true
    );
  };

  const handleUpdateClick = (event: MouseEvent, price: PriceDto) => {
    let updatedPrices = prices.slice();

    updatedPrices.forEach((i) => {
      if (i.id === price.id) {
        let validFrom = new Date("" + price.validFrom);
        let validFromYear = validFrom.getFullYear();
        let validFromDate = String(validFrom.getDate());
        if (validFromDate.length === 1) {
          validFromDate = "0" + validFromDate;
        }
        let validFromMonth = String(validFrom.getMonth() + 1); //Months are zero based
        if (validFromMonth.toString().length === 1) {
          validFromMonth = "0" + validFromMonth;
        }

        let validTo = new Date("" + price.validTo);
        let validToYear = validTo.getFullYear();
        let validToDate = String(validTo.getDate());
        if (validToDate.length === 1) {
          validToDate = "0" + validToDate;
        }
        let validToMonth = String(validTo.getMonth() + 1); //Months are zero based
        if (validToMonth.length === 1) {
          validToMonth = "0" + validToMonth;
        }

        i.validFrom = validFromYear + "-" + validFromMonth + "-" + validFromDate
            + "T00:00:00";
        i.validTo = validToYear + "-" + validToMonth + "-" + validToDate
            + "T00:00:00";


        util.serviceCallWrapper({
          method: 'PATCH',
          url: conf.urls.priceService + '/prices/' + i.id,
          data: i,
          headers: {Authorization: `Bearer ${token.accessToken}`}
        },
            () => {updatePriceListElements(currentPage)},
{
            200: {
              'SUCCESS': 'Price for item ' + i.articleId + ' is updated.'
            },
            404: {
              'ERROR': 'Price for item ' + i.articleId + ' to be updated is not found!'
            },
            409: {
              'ERROR': 'Id/articledId of price for item ' + i.articleId + ' cannot be changed!'
            },
            422: {
              'ERROR': 'Mandatory data to update the price of item ' + i.articleId + ' is not present!'
              },
          },
            () => {},
            true
        );
      }
    });
  };

  const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>, price: PriceDto) => {
    let updatedPrices = prices.slice();
    updatedPrices.map((i) => {
      if (i.id === price.id) {
        return Object.assign(i, {minQuantity: (event.target as HTMLInputElement).value});
      }
      return i;
    });
    setPrices(updatedPrices);
  };

  const handleUnitPriceChange = (event: ChangeEvent<HTMLInputElement>, price: PriceDto) => {
    let updatedPrices = prices.slice();
    updatedPrices.map((i) => {
      if (i.id === price.id) {
        return Object.assign(i, {unitPrice: (event.target as HTMLInputElement).value});
      }
      return i;
    });
    setPrices(updatedPrices);
  };

  const handleValidFromChange = (event: ChangeEvent<HTMLInputElement>, price: PriceDto) => {
    let updatedPrices = prices.slice();
    updatedPrices.map((i) => {
      if (i.id === price.id) {
        return Object.assign(i, {validFrom: (event.target as HTMLInputElement).value});
      }
      return i;
    });
    setPrices(updatedPrices);
  };

  const handleValidToChange = (event: ChangeEvent<HTMLInputElement>, price: PriceDto) => {
    let updatedPrices = prices.slice();
    updatedPrices.map((i) => {
      if (i.id === price.id) {
        return Object.assign(i, {validTo: (event.target as HTMLInputElement).value});
      }
      return i;
    });
    setPrices(updatedPrices);
  };

  const handleCurrencyChange = (event: ChangeEvent<HTMLSelectElement>, price: PriceDto) => {
    let updatedPrices = prices.slice();
    updatedPrices.map((i) => {
      if (i.id === price.id) {
        return Object.assign(i, {currency: { id: (event.target as HTMLSelectElement).value}});
      }
      return i;
    });
    setPrices(updatedPrices);
  };

  // method for search
  const handleReturnPricesById = useCallback(() => {
    if (search !== '') {
      util.serviceCallWrapper({
        method: "GET",
        url: conf.urls.priceService + '/prices?articleId=' + search,
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
        }
      },
    (result: AxiosResponse) => {
          let prices: PriceDto[] = result.data.content.map((priceIn : PriceDto) => {
          return {
            id: priceIn.id,
            articleId: priceIn.articleId,
            validFrom: priceIn.validFrom,
            validTo: priceIn.validTo,
            minQuantity: priceIn.minQuantity,
            unitPrice: priceIn.unitPrice,
            active: priceIn.active
          }
        });
        setPrices(prices);
        setCurrentPage(0);
        setTotalPages(0);
      },
{
          200: {
            'SUCCESS': 'Price for item ' + search + ' is retrieved.'
          },
          404: {
            'ERROR': 'Price for item ' + search + ' is not found!'
          },
        },
          () => {},
          true
    );
    } else {
      updatePriceListElements(currentPage);
    }
  }, [token, currentPage, search, updatePriceListElements]);

  const toggleAddNewFlag = () => {
    setAddNewFlag(!addNewFlag);
  }

  const toggleCheckedButton = (value: string) : void => {
    setCheckedButton(value);
  }

  const renderPrice = (price: PriceDto) => {
    return (
        <ExistingPrice key={price.id} price={price}
                       currencies={currencies}
                       handleDelete={(e: MouseEvent) => handleDeleteClick(e, price)}
                       handleUpdate={(e: MouseEvent) => handleUpdateClick(e, price)}
                       handleQuantity={(e: ChangeEvent<HTMLInputElement>) => handleQuantityChange(e, price)}
                       handleUnitPrice={(e: ChangeEvent<HTMLInputElement>) => handleUnitPriceChange(e, price)}
                       handleFromValidity={(e: ChangeEvent<HTMLInputElement>) => handleValidFromChange(e, price)}
                       handleToValidity={(e: ChangeEvent<HTMLInputElement>) => handleValidToChange(e, price)}
                       handleCurrency={(e: ChangeEvent<HTMLSelectElement>) => handleCurrencyChange(e, price)}
        />
    );
  }

  return (
    <Row>
      <Col xl={1} className="hidden-lg" />
      <Col xl={10} className="mainContent">
        <Container fluid className="price">
          {(token.authenticated) ? (
                  (token.isAdminPrice) ?

                      <div>
                        <Row className="pageName">

                          <Col xs={{span: 12}} className="label">
                            <Trans
                                i18nKey="price.price.priceMainLabel.label" // optional -> fallbacks to defaults if not provided
                                defaults="Price -  Overview" // optional defaultValue
                                components={{tag: <span/>}}
                            />
                          </Col>
                        </Row>

                        {addNewFlag ?
                            <div>
                              <Row className="backofficeTable">
                                <Col xs={3} className="tab"/>
                                <Col xs={1} className="tab"/>
                                <Col xs={{offset: 6, span: 2}} className="features">
                                  <Button className="filter">
                                    <img src="/common-icons/search.svg"
                                         style={{width: 18, height: 18}}
                                         alt=''/>
                                    <span className="value"> <> {t('price.priceMain.backofficeTableFilter.value')} </> </span>
                                  </Button>
                                  <Button className="addNew"
                                          onClick={() => (toggleAddNewFlag())}>
                                    <img src="/common-icons/plus-circle-black.svg"
                                         style={{width: 18, height: 18}}
                                         alt=''/>
                                    <span className="value"> <> {t('price.priceMain.backofficeTableAdd.value')} </> </span>
                                  </Button>
                                </Col>
                              </Row>
                              <NewPrice
                                  updatePriceListElements={updatePriceListElements}
                                  accessToken={token.accessToken}
                                  currentPage={currentPage}
                                  currencies={currencies}
                                  />
                              <Row className="backofficeTable">
                                <Col xs={{span: 9}} className="tab">
                                <Button className={checkedButton === "active" ? "button active" : "button"} onClick={() => {
                                  setStatusFilter("ACTIVE");
                                  setCurrentPage(0);
                                  toggleCheckedButton("active")}}> <>{t('price.priceMain.backofficeTableTab.active')}</> </Button>
                                <Button className={checkedButton === "all" ? "button active" : "button"} onClick={() => {
                                  setStatusFilter("");
                                  setCurrentPage(0);
                                  toggleCheckedButton("all")}} > <>{t('price.priceMain.backofficeTableTab.all')}</> </Button>
                                <Button className={checkedButton === "upcoming" ? "button active" : "button"} onClick={() => {
                                  setStatusFilter("UPCOMING");
                                  setCurrentPage(0);
                                  toggleCheckedButton("upcoming")}} > <>{t('price.priceMain.backofficeTableTab.upcoming')}</> </Button>
                                <Button className={checkedButton === "inactive" ? "button active" : "button"} onClick={() => {
                                  setStatusFilter("INACTIVE");
                                  setCurrentPage(0);
                                  toggleCheckedButton("inactive")}} > <>{t('price.priceMain.backofficeTableTab.inactive')}</> </Button>
                                </Col>
                                <Col xs={{span: 3}} className="features"/>
                              </Row>
                            </div>
                            :
                            <Row className="backofficeTable">
                              <Col xs={{span: 9}} className="tab">
                                <Button className={checkedButton === "active" ? "button active" : "button"} onClick={() => {
                                  setStatusFilter("ACTIVE");
                                  setCurrentPage(0);
                                  toggleCheckedButton("active")}}> <>{t('price.priceMain.backofficeTableTab.active')}</> </Button>
                                <Button className={checkedButton === "all" ? "button active" : "button"} onClick={() => {
                                  setStatusFilter("");
                                  setCurrentPage(0);
                                  toggleCheckedButton("all")}} > <>{t('price.priceMain.backofficeTableTab.all')}</> </Button>
                                <Button className={checkedButton === "upcoming" ? "button active" : "button"} onClick={() => {
                                  setStatusFilter("UPCOMING");
                                  setCurrentPage(0);
                                  toggleCheckedButton("upcoming")}} > <>{t('price.priceMain.backofficeTableTab.upcoming')}</> </Button>
                                <Button className={checkedButton === "inactive" ? "button active" : "button"} onClick={() => {
                                  setStatusFilter("INACTIVE");
                                  setCurrentPage(0);
                                  toggleCheckedButton("inactive")}} > <>{t('price.priceMain.backofficeTableTab.inactive')}</> </Button>
                              </Col>
                              <Col xs={{span: 3}} className="features">
                                <Button className="filter">
                                  <img src="/common-icons/search.svg"
                                       style={{width: 18, height: 18}}
                                       alt=''/>
                                  <span className="value"> <> {t('price.priceMain.backofficeTableFilter.value')} </> </span>
                                </Button>
                                <Button className="addNew"
                                        onClick={() => (toggleAddNewFlag())}>
                                  <img src="/common-icons/plus-circle-black.svg"
                                       style={{width: 18, height: 18}}
                                       alt=''/>
                                  <span className="value"> <> {t('price.priceMain.backofficeTableAdd.value')} </> </span>
                                </Button>
                              </Col>
                            </Row>
                        }

                        <Row className="tableHeader">
                          <Col xs={{span: 2}} className="label">
                            <>{t('price.priceMain.tableHeader.articleNumber.label')}</>
                          </Col>
                          <Col xs={1} className="label">
                            <>{t('price.priceMain.tableHeader.status.label')}</>
                          </Col>
                          <Col xs={2} className="label">
                            <>{t('price.priceMain.tableHeader.validFrom.label')}</>
                          </Col>
                          <Col xs={2} className="label">
                            <>{t('price.priceMain.tableHeader.validTo.label')}</>
                          </Col>
                          <Col xs={1} className="label">
                            <>{t('price.priceMain.tableHeader.minQuantity.label')}</>
                          </Col>
                          <Col xs={1} className="label">
                            <>{t('price.priceMain.tableHeader.unitPrice.label')}</>
                          </Col>
                          <Col xs={1} className="label"/>
                          <Col xs={1} className="label"/>
                          <Col xs={1} className="label"/>
                        </Row>
                        {prices.map((price) => renderPrice(price))}
                        <Row className="paging">
                          <Col xs={5} className="content"/>
                          <Col xs={7} className="content">
                            <Pagination className="buttons">
                              <Pagination.First onClick={openFirstPage}/>
                              <Pagination.Prev onClick={openPreviousPage}/>
                              <Pagination><>{paginationItems}</></Pagination>
                              <Pagination.Next onClick={openNextPage}/>
                              <Pagination.Last onClick={openLastPage}/>
                            </Pagination>
                          </Col>
                        </Row>
                      </div>
                      :
                      <div className='messageLogout'>
                        <>{t('price.priceMain.messageLogout.messageText')}</>
                      </div>)
              :
              <div className='spinner'>
                <Spinner animation="border" role="status" size="sm"/>{" "}Authenticating
              </div>
          }
         </Container>
        </Col>
        <Col xl={1} className="hidden-lg"/>
      </Row>
  );
};

export default Prices;