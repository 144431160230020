import React, {ChangeEvent, MouseEvent} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {Trans} from 'react-i18next';
import {CurrencyDto} from "../generated/api/priceApi";

export interface ExistingCurrencyProps {
  currency: CurrencyDto,
  handleDelete: (e: MouseEvent) => void,
  handleUpdate: (e: MouseEvent) => void,
  handleIsoCode: (e: ChangeEvent<HTMLInputElement>) => void,
  handleName: (e: ChangeEvent<HTMLInputElement>) => void,
  handleSymbol: (e: ChangeEvent<HTMLInputElement>) => void,
}

const ExistingCurrency = ({
  currency,
  handleDelete,
  handleUpdate,
  handleIsoCode,
  handleName,
  handleSymbol
} : ExistingCurrencyProps) => {

  return (
      <Row className="tableContent">
        <Col xs={3} className="value">
          <input className="isoCode" name="isoCode"
                 value={currency.isoCode}
                 onChange={handleIsoCode}/>
        </Col>
        <Col xs={3} className="value">
          <input className="name" name="name"
                 value={currency.name}
                 onChange={handleName}/>
        </Col>
        <Col xs={3} className="value">
          <input className="symbol" name="symbol"
                 value={currency.symbol}
                 onChange={handleSymbol}/>
        </Col>

        <Col xs={1} className="value"/>
        <Col xs={1} className="value">
          <Button className="function-button" onClick={(e) => {
            handleUpdate(e)
          }}>
            <img src="/price-icons/arrow-repeat.svg"
                 style={{width: 12, height: 12}} alt='update'/>
            <p><Trans
                i18nKey={'currency.existingCurrency.existingCurrency.update'}/>
            </p>
          </Button>
        </Col>
        <Col xs={1} className="value">
          <Button className="function-button" onClick={(e) => {
            handleDelete(e)
          }}>
            <img src="/price-icons/x-circle.svg"
                 style={{width: 12, height: 12}} alt='delete'/>
            <p><Trans
                i18nKey={'currency.existingCurrency.existingCurrency.delete'}/>
            </p>
          </Button>
        </Col>
      </Row>
  );
};

export default ExistingCurrency;